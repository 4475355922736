import classes from "./UserWidget.module.css";
import { DotsThreeVertical, PlusCircle } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
  screenName: string;
  avatar: string;
  navToProfile: () => void;
  openPrefs: () => void;
};

const UserWidget: React.FC<Props> = (props) => {
  let navigate = useNavigate();

  const onClick = () => {
    navigate("/buy_credits");
  };

  return (
    <div className={classes.user_widget}>
      <div
        className={classes.user_widget__avatar_wrapper}
        onClick={props.navToProfile}
      >
        <img
          className={classes.user_widget__avatar}
          id="avatar-header"
          src={props.avatar}
          alt=""
        />
      </div>
      <div className={classes.user_widget__text}>
        <div>{props.screenName}</div>
        <div>
          <PlusCircle size={20} style={{ color: "red", marginTop: "-2px" }} />
          <span style={{ marginLeft: "2px" }}>25 Credits</span>
        </div>
      </div>
      <DotsThreeVertical
        size={32}
        color={"#3844eb"}
        weight={"bold"}
        onClick={props.openPrefs}
      />
    </div>
  );
};

export default UserWidget;
