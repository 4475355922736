import classes from "./AddPhoto.module.css";
import { CameraPlus } from "@phosphor-icons/react";

interface AddPhotoProps {
  onClick: () => void;
}

const AddPhoto: React.FC<AddPhotoProps> = ({ onClick }) => {
  return (
    <div className={classes.add_photo}>
      <div className={classes.add_photo__wrapper} onClick={onClick}>
        <CameraPlus size={72} />
        <div style={{ marginTop: "-14px", fontSize: "1.5rem" }}>+</div>
        <div style={{ marginTop: "-10px", fontSize: "1.2rem" }}>Add Photo</div>
      </div>
    </div>
  );
};

export default AddPhoto;
