import React, { useContext } from "react";
import { GlobalContent } from "../interfaces/globalContent";
import AuthContext from "../store/auth-context";

import PasswordForm from "../components/Password/PasswordForm";

const PasswordPage = () => {
  const ctx = useContext<GlobalContent>(AuthContext);
  if (ctx.isLoggedIn) {
    return <PasswordForm />;
  } else {
    return <div></div>;
  }
};

export default PasswordPage;
