import React, { useEffect, useRef } from "react";
import classes from "./IdealFirstDate.module.css";

interface IdealFirstDateEditProps {
  ifd: string;
  saveHandler: (aboutMe: string) => void;
  cancelHandler: () => void;
}

const IdealFirstDateEdit: React.FC<IdealFirstDateEditProps> = ({
  ifd,
  saveHandler,
  cancelHandler,
}) => {
  const ifdInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ifdInputRef.current) {
      ifdInputRef.current.value = ifd;
    }
  }, []);

  return (
    <div className={classes.ifd}>
      <div className={classes.ifd__title_bar}>
        <div className={classes.ifd__title}>Ideal First Date:</div>
        <div className={classes.ifd__cancel} onClick={cancelHandler}>
          Cancel
        </div>
      </div>
      <textarea
        className={`${classes.ifd__text} ${classes.ifd__text_edit}`}
        id="ifd"
        ref={ifdInputRef}
      />
      <div
        className={classes.ifd__save}
        onClick={() => saveHandler(ifdInputRef.current?.value || "")}
      >
        Save
      </div>
    </div>
  );
};

export default IdealFirstDateEdit;
