import React, { useState } from "react";
import classes from "./ToggleSwitch.module.css";

interface ToggleSwitchProps {
  initialChecked?: boolean;
  onChange?: (checked: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  initialChecked = false,
  onChange,
}) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (onChange) {
      onChange(isChecked);
    }
  };

  return (
    <label className={classes.switch}>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <span className={`${classes.slider} ${classes.round}`}></span>
    </label>
  );
};

export default ToggleSwitch;
