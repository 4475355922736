import React, { useContext } from "react";
import { GlobalContent } from "../interfaces/globalContent";
import AuthContext from "../store/auth-context";
import Interests from "../components/Interests/_Interests";

const InterestsPage = () => {
  const ctx = useContext<GlobalContent>(AuthContext);
  if (ctx.isLoggedIn) {
    return <Interests />;
  } else {
    return <div></div>;
  }
};

export default InterestsPage;
