import React, { useState } from "react";
import classes from "./Description.module.css";
import EditButton from "../widgets/EditButton";

interface DescProps {
  aboutMe: string;
  editButtonsPresent: boolean;
  handleEditClick: () => void;
}

const Description: React.FC<DescProps> = ({
  aboutMe,
  editButtonsPresent,
  handleEditClick,
}) => {
  const [toggleEdit, getToggleEdit] = useState<boolean>(false);

  const editHandler = () => {
    getToggleEdit(!toggleEdit);
    handleEditClick();
  };

  return (
    <>
      <div className={classes.desc}>
        <div className={classes.desc__title_bar}>
          <div className={classes.desc__title}>A Little About Me:</div>
          {editButtonsPresent ? (
            <EditButton editMode={toggleEdit} onClick={editHandler} />
          ) : null}
        </div>
        <div className={classes.desc__text}>{aboutMe}</div>
      </div>
    </>
  );
};

export default Description;
