import { useNavigate } from "react-router-dom";
import classes from "./Prefs.module.css";
import {
  Info,
  LinkSimpleHorizontal,
  Phone,
  ArrowLeft,
} from "@phosphor-icons/react";

const HelpCenter = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <ArrowLeft
        size={40}
        onClick={props.closeSlider}
        className={classes.prefs__arrow}
      />
      <div className={classes.prefs__wrapper}>
        <div className={classes.prefs__section}>Help Center</div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/faqs");
          }}
        >
          <div className={classes.prefs__label}>Frequently Asked Questions</div>
          <Info size={32} />
        </div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/contact_us");
          }}
        >
          <div className={classes.prefs__label}>Contact Us</div>
          <Phone size={32} />
        </div>
        <div className={classes.prefs__section}>Site Information</div>
        <div className={classes.prefs__item}>
          <div
            className={classes.prefs__label}
            onClick={() => {
              props.linkOut();
              navigate("/privacy_policy");
            }}
          >
            Privacy Policy
          </div>
          <LinkSimpleHorizontal size={32} />
        </div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/terms_of_use");
          }}
        >
          <div className={classes.prefs__label}>Terms of Use</div>
          <LinkSimpleHorizontal size={32} />
        </div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/community_guidelines");
          }}
        >
          <div className={classes.prefs__label}>Community Guidelines</div>
          <LinkSimpleHorizontal size={32} />
        </div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/gdpr");
          }}
        >
          <div className={classes.prefs__label}>GDPR</div>
          <LinkSimpleHorizontal size={32} />
        </div>
      </div>
    </>
  );
};

export default HelpCenter;
