import { useEffect, useState } from "react";

import classes from "./FavoriteButton.module.css";
import { Star } from "@phosphor-icons/react";
import getProfileById from "../../helpers/getProfileById";
import addItemToArray from "../../helpers/addItemToArray";
import saveProfileById from "../../helpers/saveProfileById";
import getCurrentTimestamp from "../../helpers/getCurrentTimestamp";

interface Props {
  id: string;
  favoritedMe?: string[];
  removeParent?: () => void;
}

const FavoriteButton = (props: Props) => {
  const [favorite, setFavorite] = useState<boolean>(false);
  const myId: string = localStorage.getItem("profileId") || "";

  const toggleFavorite = async () => {
    try {
      const [user, myProfile] = await Promise.all([
        getProfileById(props.id),
        getProfileById(myId),
      ]);

      if (!user || !myProfile) {
        console.log("User not found");
        return;
      }

      if (favorite) {
        const index = user.favoritedMe?.indexOf(myId) ?? -1;
        if (index > -1) {
          (user.favoritedMe || []).splice(index, 1);
          (user.favoritedMeTimestamp || []).splice(index, 1);
        }

        const myIndex = (myProfile.favorites || []).indexOf(props.id);
        if (myIndex > -1) {
          (myProfile.favorites || []).splice(myIndex, 1);
          (myProfile.favoritesTimestamp || []).splice(myIndex, 1);
        }

        setFavorite(false);
        if (props.removeParent) {
          props?.removeParent();
        }
      } else {
        console.log("Adding Favorite");
        console.log("user.favoritedMe: ", user.favoritedMe);
        console.log("myProfile.favorites: ", myProfile.favorites);
        user.favoritedMe = addItemToArray(user.favoritedMe || [], myId);
        user.favoritedMeTimestamp = addItemToArray(
          user.favoritedMeTimestamp || [],
          getCurrentTimestamp()
        );

        myProfile.favorites = addItemToArray(
          myProfile.favorites || [],
          props.id
        );
        myProfile.favoritesTimestamp = addItemToArray(
          myProfile.favoritesTimestamp || [],
          getCurrentTimestamp()
        );

        setFavorite(true);
      }

      console.log("Save User Fave Me:", user.favoritedMe);
      console.log("Save My Faves:", myProfile.favorites);

      await Promise.all([saveProfileById(user), saveProfileById(myProfile)]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const isFavorite =
      (props.favoritedMe && props.favoritedMe.includes(myId || "0")) || false;
    setFavorite(isFavorite);
  }, [props.favoritedMe]);

  return (
    <div className={classes.favorite_button__wrapper} onClick={toggleFavorite}>
      <div
        className={
          favorite
            ? classes.favorite_button__icon_unfavorite
            : classes.favorite_button__icon
        }
      >
        <Star size={28} />
      </div>
      <div
        className={
          favorite
            ? classes.favorite_button__label_unfavorite
            : classes.favorite_button__label
        }
      >
        {favorite ? "Unfavorite" : "Favorite"}
      </div>
    </div>
  );
};

export default FavoriteButton;
