import classes from "./cardOfferBox.module.css";

const cardOfferBox = (props: any) => {
  return (
    <div className={classes.card_offer_box__wrapper}>
      <div className={classes.card_offer_box__amount}>$100</div>
      <div className={classes.card_offer_box__label}>Offer Amount</div>
    </div>
  );
};
export default cardOfferBox;
