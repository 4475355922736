import React, { useEffect, useRef } from "react";

import classes from "./MessagesDialog.module.css";

const MessagesDialog = (props: any) => {
  const myId = localStorage.getItem("profileId");
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // console.log("Props from MessagesDialog: ", props);

  useEffect(() => {
    props.scrollToBottom(messagesEndRef.current);
  }, [props]);

  if (!props.conv) {
    return (
      <div className={classes.messages_dialog}>
        <div className={classes.messages_dialog__center}>No messages yet</div>
      </div>
    );
  }
  return (
    <div className={classes.messages_dialog} ref={messagesEndRef}>
      {props.conv.map((message: any, index: number) => {
        if (message.user === myId) {
          return (
            <div className={classes.messages_dialog__row_right} key={index}>
              <div className={classes.messages_dialog_bubble_right}>
                {message.message}
              </div>
            </div>
          );
        } else {
          return (
            <div className={classes.messages_dialog__row_left} key={index}>
              <div className={classes.messages_dialog_bubble_left}>
                {message.message}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default MessagesDialog;
