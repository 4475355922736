import React, { useState, useRef, ChangeEvent } from "react";
import imageCompression from "browser-image-compression";
import classes from "./Upload.module.css";
import { firebaseApp } from "../../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  UploadTaskSnapshot,
} from "firebase/storage";

interface ImageUploaderProps {
  onUpload: (urls: string[]) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onUpload }) => {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const firebase = firebaseApp;

  // const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   if (files) {
  //     const fileList = Array.from(files);
  //     setSelectedImages(fileList);
  //     setUploadProgress(Array(fileList.length).fill(0));
  //   }
  // };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      const compressedFiles = [];
      for (let file of fileList) {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1, // (1MB) The maximum size of the output file in megabytes
          useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default)
        });
        compressedFiles.push(compressedFile);
      }
      setSelectedImages(compressedFiles);
      setUploadProgress(Array(compressedFiles.length).fill(0));
    }
  };

  const handleUpload = async () => {
    if (!firebase || selectedImages.length === 0) return;

    const storage = getStorage(firebase);
    const storageRef = ref(storage, "images");

    try {
      const uploadPromises = selectedImages.map((image, index) => {
        const filePath = `images/${image.name}`;
        const uploadTask = uploadBytesResumable(
          ref(storageRef, filePath),
          image
        );

        return new Promise<string>((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot: UploadTaskSnapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setUploadProgress((prevProgress) => {
                const updatedProgress = [...prevProgress];
                updatedProgress[index] = progress;
                return updatedProgress;
              });
            },
            (error) => {
              console.log("error", error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      });

      const uploadResults = await Promise.all(uploadPromises);
      onUpload(uploadResults);
    } catch (error) {
      console.log("error", error);
    }
  };

  const openFileSelector = () => {
    fileInputRef.current!.click();
  };

  return (
    <div>
      {/* <input
        id='upload-button'
        className={classes.upload__button}
        type="file"
        accept="image/png, image/jpeg, image/heic"
        onChange={handleFileSelect}
        ref={fileInputRef}
        multiple
      />
      <button onClick={handleUpload} disabled={selectedImages.length > 0 ? false : true}>Upload</button> */}

      <input
        className={classes.upload__button}
        type="file"
        style={{ display: "none" }}
        id="upload-button"
        multiple
        accept="image/png, image/jpeg, image/heic, image/jpg"
        onChange={handleFileSelect}
        ref={fileInputRef}
      />
      <label htmlFor="upload-button">
        <button className="primary-button" onClick={openFileSelector}>
          Select Images
        </button>
        <button
          className="secondary-button"
          onClick={handleUpload}
          disabled={selectedImages.length > 0 ? false : true}
        >
          Upload
        </button>
      </label>
      {selectedImages.length > 0 &&
        selectedImages.map((image, index) => (
          <div className={classes.selected_image} key={index}>
            <img src={URL.createObjectURL(image)} alt="preview" height="50" />
            <p>{image.name}</p>
            {uploadProgress[index] > 0 && (
              <progress value={uploadProgress[index]} max={100} />
            )}
          </div>
        ))}
    </div>
  );
};

export default ImageUploader;
