import React, { useState, useEffect } from "react";

import { collection, query, onSnapshot, where } from "firebase/firestore";
import { db } from "../../firebase";

import classes from "../Grid.module.css";
import OffersCard from "./OffersCard";
import { ProfileProps } from "../../interfaces/profileInterface";
interface OffersRcvdGridProps {
  profiles: ProfileProps[];
  fetchRelationships: () => void;
}

const OffersRcvdGrid: React.FC<OffersRcvdGridProps> = ({
  profiles,
  fetchRelationships,
}) => {
  const [reloadMessages, setReloadMessages] = useState<boolean>(false);

  const myId = localStorage.getItem("profileId") || "";

  useEffect(() => {
    const collectionRef = collection(db, "relationships");
    const q = query(collectionRef, where("offereeId", "==", myId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log("Snapshot received. ", snapshot.docs.length, snapshot.docs);
      setReloadMessages(false);
      fetchRelationships();
    });
    return () => unsubscribe();
  }, [reloadMessages]);

  return (
    <div className={classes.grid}>
      <div className={classes.grid__text}>Received Requests:</div>
      <div className={classes.grid__cards}>
        {profiles.map((profile, index) => (
          <OffersCard className={classes.card} key={index} {...profile} />
        ))}
      </div>
    </div>
  );
};

export default OffersRcvdGrid;
