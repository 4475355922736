import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { RelationshipProps } from "../interfaces/relationshipInterface";
import getCurrentTimestamp from "./getCurrentTimestamp";

interface UpdateRelationship {
  relationship: RelationshipProps;
  message: string;
  [key: string]: any;
}

const saveMessage = async ({ relationship, message }: UpdateRelationship) => {
  console.log("Save Relationship By Id: ", relationship.id);
  const documentRef = doc(db, "relationships", relationship.id || "0");
  const myId = localStorage?.getItem("profileId") || "0";
  try {
    const documentSnapshot = await getDoc(documentRef);

    if (documentSnapshot.exists()) {
      const documentData = documentSnapshot.data() as RelationshipProps;
      // Update document here
      if (documentData?.conv) {
        const timestamp = getCurrentTimestamp();

        documentData?.conv.push({
          timestamp: timestamp,
          message: message,
          user: myId,
        });
        await updateDoc(documentRef, documentData);
      }
    } else {
      console.log("No document found with the provided ID.");
    }
  } catch (error) {
    console.error("Error finding document:", error);
  }
};

export default saveMessage;
