import classes from "./AvatarCircle.module.css";

interface avatarCircleProps {
  avatarCircle: string;
}

const AvatarCircle: React.FC<avatarCircleProps> = ({ avatarCircle }) => {
  return (
    <img src={avatarCircle} className={classes.avatar_circle} alt="alt text" />
  );
};

export default AvatarCircle;
