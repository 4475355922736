import { ProfileProps } from "../interfaces/profileInterface";
import getProfileById from "./getProfileById";

const myId = localStorage.getItem("profileId") || "";

const getProfilesByIds = async (ids: string[]): Promise<ProfileProps[]> => {
  const profiles: ProfileProps[] = [];

  for (const id of ids) {
    if (id === myId) {
      continue;
    }
    const profile = await getProfileById(id);
    if (profile) {
      profiles.push(profile);
    }
  }

  return profiles;
};

export default getProfilesByIds;
