import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { RelationshipProps } from "../interfaces/relationshipInterface";

const getRelationshipsByOfferee = async (
  id: string
): Promise<[RelationshipProps[], string[]]> => {
  try {
    if (!id) {
      return [[], []];
      // throw new Error("Invalid ID");
    }

    const myId = localStorage.getItem("profileId") || "";
    console.log("Getting Relationships by Offeree: ", id);

    const collectionRef = collection(db, "relationships");
    const q = query(collectionRef, where("offereeId", "==", id));
    const querySnapshot = await getDocs(q);

    const idIndex: string[] = [];

    const returnArray = querySnapshot.docs.map((doc) => {
      const data: RelationshipProps = {
        id: doc.id,
        ...doc.data(),
      } as RelationshipProps;

      idIndex.push(data?.offererId || "");

      return data;
    });
    console.log("Relationships by Offeree: ", returnArray);

    return [returnArray, idIndex];
  } catch (error) {
    console.error("Error retrieving document:", error);
    throw error;
  }
};

export default getRelationshipsByOfferee;
