import React, { useState, useEffect } from "react";

import {
  collection,
  query,
  onSnapshot,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

import classes from "./_Interests.module.css";
import InterestsTabs from "./InterestsTabs";
import InterestsGrid from "./InterestsGrid";
import getProfileById from "../../helpers/getProfileById";
import getProfilesByIds from "../../helpers/getProfilesByIds";
import getRelationshipsByIds from "../../helpers/getRelationshipsByIds";
import sortParallelArrays from "../../helpers/sortParallelArrays";
import { ProfileProps } from "../../interfaces/profileInterface";

const Interests: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>("viewed_me");
  const [visitedMeProfiles, setVisitedMeProfiles] = useState<ProfileProps[]>(
    []
  );
  const [favoritedMeProfiles, setFavoritedMeProfiles] = useState<
    ProfileProps[]
  >([]);
  const [favoritesProfiles, setFavoritesProfiles] = useState<ProfileProps[]>(
    []
  );

  interface Offers {
    [key: string]: Array<string> | undefined;
  }

  const [relationships, setRelationships] = useState<Offers>({});
  const [reloadMessages, setReloadMessages] = useState<boolean>(false);

  const myId = localStorage.getItem("profileId") || "";

  let gridComponent: JSX.Element | null = null;

  const fetchProfiles = async () => {
    const myProfile = await getProfileById(
      localStorage.getItem("profileId") || ""
    );
    if (myProfile) {
      console.log("MyProfile", myProfile);
      let visitedMe = myProfile.visitedMe || [];
      let visitedMeTimestamp = myProfile.visitedMeTimestamp || [];
      [visitedMeTimestamp, visitedMe] = sortParallelArrays(
        visitedMeTimestamp,
        visitedMe
      );

      let favoritedMe = myProfile.favoritedMe || [];
      let favoritedMeTimestamp = myProfile.favoritedMeTimestamp || [];
      [favoritedMeTimestamp, favoritedMe] = sortParallelArrays(
        favoritedMeTimestamp,
        favoritedMe
      );

      let favorites = myProfile.favorites || [];
      let favoritesTimestamp = myProfile.favoritesTimestamp || [];
      [favoritesTimestamp, favorites] = sortParallelArrays(
        favoritesTimestamp,
        favorites
      );

      const visitedMeProfiles = await getProfilesByIds(visitedMe);
      const visitedMeRelationships = await getRelationshipsByIds(visitedMe);
      const favoritedMeProfiles = await getProfilesByIds(favoritedMe);
      const favoritesProfiles = await getProfilesByIds(favorites);

      setVisitedMeProfiles(visitedMeProfiles);
      setFavoritedMeProfiles(favoritedMeProfiles);
      setFavoritesProfiles(favoritesProfiles);
      setRelationships(visitedMeRelationships);

      console.log("Relationships:", visitedMeRelationships);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    const userDocRef = doc(db, "users", myId);

    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      console.log("Snapshot received. ", snapshot.data());
      setReloadMessages(false);
      fetchProfiles();
    });
    return () => unsubscribe();
  }, [reloadMessages]);

  switch (selectedTab) {
    case "viewed_me":
      gridComponent = (
        <InterestsGrid
          profiles={visitedMeProfiles}
          type="viewed_me"
          relationshipIds={relationships}
          fetchProfiles={fetchProfiles}
        />
      );
      break;
    case "likes_me":
      gridComponent = (
        <InterestsGrid
          profiles={favoritedMeProfiles}
          type="likes_me"
          fetchProfiles={fetchProfiles}
        />
      );
      break;
    case "i_like":
      gridComponent = (
        <InterestsGrid
          profiles={favoritesProfiles}
          type="favorites"
          fetchProfiles={fetchProfiles}
        />
      );
      break;
    default:
      gridComponent = null;
  }

  return (
    <div className={classes.interests}>
      <InterestsTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {gridComponent}
    </div>
  );
};

export default Interests;
