import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { RelationshipProps } from "../interfaces/relationshipInterface";
import getProfileById from "./getProfileById";

interface SlimProfile {
  id?: string;
  screenName?: string;
  photoUrl?: Array<string>;
  lastActive?: number;
  age?: number;
  location?: string;
  acceptedOffer?: number;
  firstLine?: string;
}

async function getToplineProfiles(
  relationships: RelationshipProps[]
): Promise<SlimProfile[]> {
  const profiles: any = [];
  for (const relationship of relationships) {
    if (relationship?.offereeId) {
      const profile = await getProfileById(relationship.offereeId);
      const convLen = relationship?.conv?.length || 0;

      let firstLine;

      if (convLen > 0) {
        firstLine =
          relationship?.conv?.[relationship?.conv.length - 1]?.message;
      }

      if (profile && profile.photoUrl) {
        const slimProfile: SlimProfile = {
          id: profile.id,
          screenName: profile.screenName,
          photoUrl: profile?.photoUrl,
          lastActive: profile.lastActive,
          age: profile.age,
          location: profile.location,
          acceptedOffer: profile.acceptedOffer,
          firstLine: firstLine,
        };
        profiles.push(slimProfile);
      }
    }
  }

  return profiles;
}

export default getToplineProfiles;
