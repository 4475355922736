import React, { useEffect } from "react";
import classes from "./Interests.module.css";
import GroupButtons from "../widgets/GroupButtons";
interface InterestsEditProps {
  interests: string[];
  saveHandler: (interests: string[]) => void;
  cancelHandler: () => void;
}

const InterestsEdit: React.FC<InterestsEditProps> = ({
  interests,
  saveHandler,
  cancelHandler,
}) => {
  console.log("Interests: ", interests);

  interface GroupButtonObject {
    id: string;
    label: string;
    checked: boolean;
  }

  const interestOptions: GroupButtonObject[] = [
    {
      id: "Dating",
      label: "Dating",
      checked: interests.includes("Dating"),
    },
    {
      id: "Long Term",
      label: "Long Term",
      checked: interests.includes("Long Term"),
    },
    {
      id: "Friendship",
      label: "Friendship",
      checked: interests.includes("Friendship"),
    },
    {
      id: "Discrete Affairs",
      label: "Discrete Affairs",
      checked: interests.includes("Discrete Affairs"),
    },
    {
      id: "Mentorship",
      label: "Mentorship",
      checked: interests.includes("Mentorship"),
    },
    {
      id: "Casual",
      label: "Casual",
      checked: interests.includes("Casual"),
    },
  ];

  const buttonHandler = (group: string, option: string) => {
    console.log("Button Handlerr", group, option, interests);
    if (interests.includes(option)) {
      const index = interests.indexOf(option);
      interests.splice(index, 1);
    } else {
      interests.push(option);
    }
  };

  return (
    <div className={classes.interests}>
      <div className={classes.interests__title_bar}>
        <div className={classes.interests__title}>Interests:</div>
        <div className={classes.interests__cancel} onClick={cancelHandler}>
          Cancel
        </div>
      </div>
      <div className={classes.interests__grid}>
        <GroupButtons
          label=""
          data={interestOptions}
          group="interests"
          handler={buttonHandler}
          type="checkbox"
        />
      </div>
      <div
        className={classes.interests__save}
        onClick={() => saveHandler(interests)}
      >
        Save
      </div>
    </div>
  );
};

export default InterestsEdit;
