import classes from "./BuyCreditsButton.module.css";
import { useNavigate } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
  label: string;
};

const BuyCreditsButton: React.FC<Props> = (props) => {
  let navigate = useNavigate();

  const onClick = () => {
    navigate("/buy_credits");
  };

  return (
    <div className={classes.cta_button} onClick={onClick}>
      {props.label}
    </div>
  );
};

export default BuyCreditsButton;
