import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Cards from "../widgets/Cards";
import getProfiles from "../../helpers/getProfiles";
import { ProfileProps } from "../../interfaces/profileInterface";
import { DocumentData } from "firebase/firestore";

import classes from "./_Search.module.css";

const Search = () => {
  const [cards, setCards] = useState<ProfileProps[]>([]);
  const [prevSnapshot, setPrevSnapshot] = useState<DocumentData | null>(null);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    getProfiles(10, prevSnapshot).then((returnedValues) => {
      const { recentUsers, prevSnapshot } = returnedValues;

      if (recentUsers.length === 0) {
        setHasMore(false);
        return;
      }

      setPrevSnapshot(prevSnapshot);
      setCards((prevCards) => [...prevCards, ...recentUsers]);
    });
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  return (
    <div className={classes.search}>
      <h1>Search</h1>
      <div className={classes.grid}>
        <InfiniteScroll
          dataLength={cards.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Cards profiles={cards} type="standard" />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Search;
