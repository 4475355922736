import { RelationshipProps } from "../interfaces/relationshipInterface";
import { db } from "../firebase";
import { doc, deleteDoc } from "firebase/firestore";

const deleteRelationship = async (relationship: RelationshipProps) => {
  try {
    console.log("Delete Relationship: ", relationship);

    const relationshipDocRef = doc(db, "relationships", relationship?.id || "");

    await deleteDoc(relationshipDocRef);

    console.log("Relationship deleted successfully.");
  } catch (error) {
    console.error("Error deleting relationship:", error);
  }
};

export default deleteRelationship;
