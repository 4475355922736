import classes from "./cancelButton.module.css";
import { X } from "@phosphor-icons/react";

const cancelButton = (props: any) => {
  return (
    <div className={classes.cancel_button__wrapper}>
      <div className={classes.cancel_button__icon}>
        <X size={28} />
      </div>
      <div className={classes.cancel_button__label}>Cancel</div>
    </div>
  );
};
export default cancelButton;
