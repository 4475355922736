import { collection, where, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import { RelationshipProps } from "../interfaces/relationshipInterface";

const getRelationshipsByOfferer = async (
  id: string
): Promise<[RelationshipProps[], string[]]> => {
  try {
    if (!id) {
      return [[], []];
      // throw new Error("Invalid ID");
    }

    console.log("Getting Relationships by Offerer: ", id);

    const collectionRef = collection(db, "relationships");
    const q = query(collectionRef, where("offererId", "==", id));
    const querySnapshot = await getDocs(q);

    const idIndex: string[] = [];

    const returnArray = querySnapshot.docs.map((doc) => {
      const data: RelationshipProps = {
        id: doc.id,
        ...doc.data(),
      } as RelationshipProps;
      idIndex.push(data?.offereeId || "");
      return data;
    });

    console.log("Relationships by Offerer: ", returnArray);

    return [returnArray, idIndex];
  } catch (error) {
    console.error("Error retrieving document:", error);
    throw error;
  }
};

export default getRelationshipsByOfferer;
