import React, { useState } from "react";
import classes from "./OffersTabs.module.css";

interface TabsProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const OffersTabs: React.FC<TabsProps> = ({ selectedTab, setSelectedTab }) => {
  const tabClick = (e: any) => {
    console.log(e.target.dataset.name);
    setSelectedTab(e.target.dataset.name);
  };

  return (
    <div className={classes.offers_tabs}>
      <div
        onClick={tabClick}
        className={`${classes.offers_tabs__item} ${
          selectedTab === "requests" && classes.offers_tabs__item_selected
        }`}
        data-name="requests"
      >
        Received Requests
      </div>
      <div
        onClick={tabClick}
        className={`${classes.offers_tabs__item} ${
          selectedTab === "offers" && classes.offers_tabs__item_selected
        }`}
        data-name="offers"
      >
        Sent Offers
      </div>
    </div>
  );
};

export default OffersTabs;
