import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Detail from "../components/Detail/_Detail";
import { GlobalContent } from "../interfaces/globalContent";
import AuthContext from "../store/auth-context";

const DetailPage = () => {
  const ctx = useContext<GlobalContent>(AuthContext);
  const params = useParams();
  if (ctx.isLoggedIn) {
    const id = params.id?.toString() || "";
    return (
      <Detail editButtonsPresent={false} recordId={id} offerBoxPresent={true} />
    );
  } else {
    return <div></div>;
  }
};

export default DetailPage;
