import { createSlice } from '@reduxjs/toolkit';

interface TestState { 
    test: string;
}

const initialState:TestState = {
    test: 'suck',
}

export const testReducer = createSlice({
   name: 'test deez nuts',
    initialState,
    reducers: {
        testChange: (state, action) => {
            state.test = action.payload;
        }
    }
})

export default testReducer.reducer;
export const { testChange } = testReducer.actions;