import React, { useState, createContext } from "react";
import getProfileByEmail from "../helpers/getProfileByEmail";
import { GlobalContent } from "../interfaces/globalContent";

let logoutTimer: NodeJS.Timeout;

export const AuthContext = createContext<GlobalContent>({
  isLoggedIn: false,
  userEmail: null,
  userAvatar: null,
  token: null,
  login: () => {},
  logout: () => {},
});

const calcRemainingTime = (expirationTime: string): number => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = (): {
  token: string;
  duration: number;
  userEmail: string;
  userAvatar: string;
} | null => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");
  const storedUserEmail = localStorage.getItem("userEmail");
  const storedUserAvatar = localStorage.getItem("userAvatar");

  if (!storedToken || !storedExpirationDate || !storedUserEmail) {
    return null;
  }

  const remainingTime: number = calcRemainingTime(storedExpirationDate);

  if (remainingTime <= 60000) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userAvatar");
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
    userEmail: storedUserEmail,
    userAvatar: storedUserAvatar || "",
  };
};

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const tokenData = retrieveStoredToken();
  const [token, setToken] = useState<string | null>(tokenData?.token || "");
  const [userEmail, setUserEmail] = useState<string | null>(
    tokenData?.userEmail || ""
  );
  const [userAvatar, setUserAvatar] = useState<string | null>(null);

  const userIsLoggedIn = !!token;
  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userAvatar");
    localStorage.removeItem("profileId");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };

  const loginHandler = (
    token: string,
    expirationTime: string,
    userEmail: string
  ) => {
    console.log("loginHandler: ", token, expirationTime, userEmail);
    setToken(token);
    setUserEmail(userEmail);

    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expirationTime);
    localStorage.setItem("userEmail", userEmail);

    getProfileByEmail(userEmail)
      .then((user) => {
        if (user) {
          console.log(
            "User found by email on login.",
            user,
            user?.photoUrl?.[0]
          );
          localStorage.setItem("profileId", user?.id || "");
          setUserAvatar(user?.photoUrl?.[0] || "");
          localStorage.setItem(
            "userAvatar",
            user?.photoUrl?.[0] || "./images/missing-male.png"
          );
          console.log("user avatar: ", localStorage.getItem("userAvatar"));
          localStorage.setItem("screenName", user?.screenName || "");
        } else {
          console.log("User not found by email on login.");
          localStorage.setItem("userAvatar", "");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const remainingTime = calcRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const contextValue: GlobalContent = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    userEmail: userEmail,
    userAvatar: userAvatar,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
