import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAVUHd3K5ThdnmaQc9hVsHsGaZs2Vsc5zs",
  authDomain: "studfinder-b9fb1.firebaseapp.com",
  projectId: "studfinder-b9fb1",
  storageBucket: "studfinder-b9fb1.appspot.com",
  messagingSenderId: "55837925210",
  appId: "1:55837925210:web:9e50903c8cd06791d35d63",
  measurementId: "G-44N2PFRV0S",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, app as firebaseApp };
