import { doc, getDoc, DocumentData } from "firebase/firestore";
import { db } from "../firebase";
import { ProfileProps } from "../interfaces/profileInterface";

const getProfileById = async (userId: string): Promise<ProfileProps | null> => {
  if (!userId) {
    return null;
  }
  try {
    const userDocRef = doc(db, "users", userId);
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data() as DocumentData;
      return { id: docSnapshot.id, ...userData } as ProfileProps;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error retrieving user:", error);
    throw error;
  }
};

export default getProfileById;
