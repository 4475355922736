import React from "react";
import classes from "../Grid.module.css";
import OffersCard from "./OffersCard";
import { ProfileProps } from "../../interfaces/profileInterface";

interface OffersSentGridProps {
  profiles: ProfileProps[];
}

const OffersSentGrid: React.FC<OffersSentGridProps> = ({ profiles }) => {
  return (
    <div className={classes.grid}>
      <div className={classes.grid__text}>Sent Offers:</div>
      <div className={classes.grid__cards}>
        {profiles.map((profile, index) => (
          <OffersCard className={classes.card} key={index} {...profile} />
        ))}
      </div>
    </div>
  );
};

export default OffersSentGrid;
