import React, { useRef, useEffect } from "react";
import classes from "./PrefsPanel.module.css";
import NotificationPrefs from "./NotificationPrefs";

interface PrefsPanelProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  position?: "left" | "right";
}

const PrefsPanel: React.FC<PrefsPanelProps> = ({
  isOpen,
  children,
  onClose,
  position = "right",
}) => {
  const bodyRef = useRef(document.querySelector("body"));

  useEffect(() => {
    const updatePageScroll = () => {
      if (bodyRef.current) {
        bodyRef.current.style.overflow = isOpen ? "hidden" : "";
      }
    };

    updatePageScroll();
  }, [isOpen]);

  return (
    <div className={`${classes.prefs_panel__container}`}>
      <div
        className={`${classes.prefs_panel} ${isOpen ? classes.open : ""} ${
          classes[position]
        }`}
        role="dialog"
      >
        {children}
      </div>
      <div
        className={`${classes.prefs_panel__backdrop} ${
          isOpen ? classes.open : ""
        }`}
        onClick={onClose}
      />
    </div>
  );
};

export default PrefsPanel;
