import classes from "./OffersCard.module.css";
import Card from "../widgets/Card";
import { ProfileProps } from "../../interfaces/profileInterface";

const OffersCard: React.FC<ProfileProps> = (props) => {
  return (
    <div>
      <Card profile={props} type="offers" />
      <div></div>
    </div>
  );
};

export default OffersCard;
