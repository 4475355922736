import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { File, Binoculars, Star, Coins, ChatDots } from "@phosphor-icons/react";

import AuthContext from "../../store/auth-context";

import UserWidget from "./UserWidget";
import BuyCreditsButton from "./BuyCreditsButton";

import classes from "./Header.module.css";
import { GlobalContent } from "../../interfaces/globalContent";
interface HeaderProps {
  openPrefs: () => void;
}

const Header: React.FC<HeaderProps> = ({ openPrefs }) => {
  const ctx = useContext<GlobalContent>(AuthContext);
  const navigate = useNavigate();
  const avatar =
    localStorage.getItem("userAvatar") || "./images/missing-male.png";

  const handleLoginLogout = () => {
    if (ctx.isLoggedIn) {
      ctx.logout();
    }
    navigate("/login");
  };

  const navToProfile = () => {
    navigate("/profile");
  };

  return (
    <>
      <header className={classes.main_header}>
        <nav className={classes.nav_left}>
          <Link to="/" className={classes.nav_link}>
            <img
              className={classes.nav_logo}
              src="../images/radar-screen.jpg"
              alt=""
            />
          </Link>
          <ul className={classes.nav_list}>
            {ctx.isLoggedIn && (
              <>
                <li className={classes.nav_item}>
                  <Link to="/search" className={classes.nav_link}>
                    <div className={classes.nav_icon}>
                      <Binoculars size={50} />
                    </div>
                    <div className={classes.nav_icon_label}>Search</div>
                  </Link>
                </li>
                <li className={classes.nav_item}>
                  <Link to="/interests" className={classes.nav_link}>
                    <div className={classes.nav_icon}>
                      <Star size={50} />
                    </div>
                    <div className={classes.nav_icon_label}>Favorites</div>
                  </Link>
                </li>
                <li className={classes.nav_item}>
                  <Link to="/offers" className={classes.nav_link}>
                    <div className={classes.nav_icon}>
                      <Coins size={50} />
                    </div>
                    <div className={classes.nav_icon_label}>Offers</div>
                  </Link>
                </li>
                <li className={classes.nav_item}>
                  <Link to="/messages" className={classes.nav_link}>
                    <div className={classes.nav_icon}>
                      <ChatDots size={50} />
                      <div className={classes.nav_icon_label}>Messages</div>
                    </div>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
        <div className={classes.nav_right}>
          <div
            className={classes.user_name}
            style={{ marginTop: "10px", cursor: "pointer" }}
          ></div>
          <div
            className={ctx.isLoggedIn ? classes.user_name : classes.login}
            style={{ marginRight: "5px", marginTop: "10px", cursor: "pointer" }}
            onClick={handleLoginLogout}
          >
            {ctx.isLoggedIn ? localStorage.getItem("userEmail") : "LOG IN"}
          </div>
          {ctx.isLoggedIn && (
            <>
              <div
                className={classes.nav_item}
                style={{ marginRight: "140px" }}
              >
                <BuyCreditsButton label="Buy Credits" />
              </div>
              <div className={classes.nav_item}>
                <UserWidget
                  screenName={localStorage.getItem("screenName") || ""}
                  navToProfile={navToProfile}
                  openPrefs={openPrefs}
                  avatar={avatar}
                />
              </div>
            </>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
