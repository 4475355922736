import classes from "./GroupButton.module.css";

const groupButton = (props: any) => {
  return (
    <label htmlFor={props.id} className={classes.button_label}>
      <input
        className={classes.button_input}
        type={props.type}
        name={props.id}
        id={props.id}
        onChange={props.onChange}
        checked={props.checked}
        data-group={props.group}
      />
      <span className={classes.custom_button}>{props.label}</span>
    </label>
  );
};
export default groupButton;
