import React, { useEffect, useRef } from "react";
import classes from "./Description.module.css";

interface DescEditProps {
  aboutMe: string;
  saveHandler: (aboutMe: string) => void;
  cancelHandler: () => void;
}

const DescriptionEdit: React.FC<DescEditProps> = ({
  aboutMe,
  saveHandler,
  cancelHandler,
}) => {
  const aboutMeInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (aboutMeInputRef.current) {
      aboutMeInputRef.current.value = aboutMe;
    }
  }, []);

  return (
    <>
      <div className={classes.desc}>
        <div className={classes.desc__title_bar}>
          <div className={classes.desc__title}>A Little About Me:</div>
          <div className={classes.desc__cancel} onClick={cancelHandler}>
            Cancel
          </div>
        </div>
        <textarea
          className={`${classes.desc__text} ${classes.desc__text_edit}`}
          id="about_me"
          ref={aboutMeInputRef}
        />
        <div
          className={classes.desc__save}
          onClick={() => saveHandler(aboutMeInputRef.current?.value || "")}
        >
          Save
        </div>
      </div>
    </>
  );
};

export default DescriptionEdit;
