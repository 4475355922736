import React from "react";
import classes from "./MessagesTop.module.css";

interface SlimProfile {
  id?: string;
  screenName?: string;
  photoUrl?: Array<string>;
  lastActive?: number;
  age?: number;
  location?: string;
  acceptedOffer?: number;
  firstLine?: string;
}

const MessagesTop: React.FC<SlimProfile> = (props) => {
  // console.log("MessagesTop: ", props);
  if (Object.keys(props).length === 0)
    return <div className={classes.messages_top__message_top}></div>;
  return (
    <div className={classes.messages_top__message_top}>
      <img
        className={classes.messages_top__message_avatar}
        src={`${props.photoUrl?.[0] || ""}`}
        alt="alt text"
      />
      <div className={classes.messages_top__connection_info}>
        <div>{props.screenName}</div>
        <div>
          {props.age} • {props.location}
        </div>
        <div className={classes.messages_top__accepted}>Offer Accepted!</div>
        <div>First date incentive: ${props.acceptedOffer}</div>
      </div>
    </div>
  );
};

export default MessagesTop;
