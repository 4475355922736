import React, { useEffect, useState } from "react";
import classes from "./OfferPendingBox.module.css";
import saveNewOffer from "../../helpers/saveNewOffer";
import { Modal, ModalBody } from "reactstrap";
import { ProfileProps } from "../../interfaces/profileInterface";
import { RelationshipProps } from "../../interfaces/relationshipInterface";
import OfferSentConfirmModal from "../widgets/OfferSentConfirmModal";
import deleteRelationship from "../../helpers/deleteRelationship";

interface OfferBoxProps {
  profile: ProfileProps;
  relationship: RelationshipProps;
  closeModal?: (amount: number) => void;
  offerStatusChanged: (newStatus: string) => void;
}

const OfferPendingBox: React.FC<OfferBoxProps> = ({
  profile,
  relationship,
  closeModal,
  offerStatusChanged,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(
    relationship?.offers
      ? Number(relationship.offers[relationship.offers.length - 1].amount)
      : 0
  );

  useEffect(() => {
    setAmount(
      relationship?.offers
        ? Number(relationship.offers[relationship.offers.length - 1].amount)
        : 0
    );
  }, [relationship]);

  const closeModalLocal = () => {
    setModalOpen(false);
  };

  const cancelOffer = () => {
    deleteRelationship(relationship).then(() => {
      offerStatusChanged("none");
    });
  };

  return (
    <>
      <div className={classes.offer_pending_box}>
        <div className={classes.offer_pending_box__title}>
          {profile.screenName} is considering your offer!
        </div>
        <div className={classes.offer_pending_box__subtitle}>
          Click Offer Amount to edit your offer.
        </div>
        <div className={classes.offer_pending_box__amount_wrapper}>
          <div className={classes.offer_pending_box__amount}>${amount}</div>
          <div className={classes.offer_pending_box__subtitle}>
            Offer Amount
          </div>
        </div>
        <div
          className={classes.offer_pending_box__button}
          onClick={cancelOffer}
        >
          Cancel Offer
        </div>
        {relationship?.id}
      </div>
      <Modal
        className={classes.modal}
        isOpen={modalOpen}
        centered={true}
        backdrop={true}
        // toggle={closeModal}
      >
        <ModalBody>
          {modalOpen && (
            <OfferSentConfirmModal
              profile={profile}
              closeModal={closeModal ? closeModal : closeModalLocal}
              amount={amount}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default OfferPendingBox;
