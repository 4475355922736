import { useEffect } from "react";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  Firestore,
} from "firebase/firestore";
import { db } from "../firebase";
import { ProfileProps } from "../interfaces/profileInterface";

const saveProfileById = async (profile: ProfileProps) => {
  try {
    const userCollectionRef = collection(db, "users");

    const stringFields = [
      "lastSeenFrom",
      "favorite",
      "lastActive",
      "screenName",
      "age",
      "dob",
      "location",
      "gender",
      "sendOrReceive",
      "lookingFor",
      "email",
      "bodyType",
      "height",
      "weight",
      "hasChildren",
      "smoking",
      "drinking",
      "education",
      "occupation",
      "income",
      "description",
      "ifd",
    ];

    const arrayFields = [
      "photoUrl",
      "interests",
      "favorites",
      "favoritesTimestamp",
      "favoritedMe",
      "favoritedMeTimestamp",
      "visitedMe",
      "visitedMeTimestamp",
    ];

    const numberFields = ["currentOffer", "acceptedOffer"];

    let newProfileData: ProfileProps = {};

    stringFields.forEach((field) => {
      newProfileData[field] = profile?.[field] || "";
    });

    arrayFields.forEach((field) => {
      newProfileData[field] = profile?.[field] || [];
    });

    numberFields.forEach((field) => {
      newProfileData[field] = profile?.[field] || 0;
    });

    const docRef = await addDoc(userCollectionRef, newProfileData);

    await updateDoc(doc(userCollectionRef, docRef.id), {
      id: docRef.id,
    });

    console.log("New profile ID: ", docRef.id || "");
    localStorage.setItem("profileId", docRef?.id || "");

    console.log("Item saved.", newProfileData);
    return newProfileData;
  } catch (error) {
    alert("Error writing data: " + error);
  }
};

export default saveProfileById;
