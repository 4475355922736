import { ProfileProps } from "../interfaces/profileInterface";
import { RelationshipProps } from "../interfaces/relationshipInterface";
import getRelationshipByPair from "./getRelationshipByPair";

const myId = localStorage.getItem("profileId") || "";

interface Offers {
  [key: string]: Array<string> | undefined;
}

const getRelationshipsByIds = async (ids: string[]): Promise<Offers> => {
  const relationships: Offers = {};

  for (const id of ids) {
    if (id === myId || !id) {
      continue;
    }
    const [relationship, idIndexes] = await getRelationshipByPair(myId, id);

    if (relationship && relationship?.[0]?.length > 0) {
      let index: string = relationship?.[0]?.[1] || "";
      relationships[index] = [
        relationship?.[0]?.[0]?.id,
        relationship?.[0]?.[0]?.offers,
      ];
    }
  }

  return relationships;
};

export default getRelationshipsByIds;
