import React from "react";

import classes from "./MessagesList.module.css";
import MessagesListItem from "./MessagesListItem";
import { SlimProfile } from "../../interfaces/slimProfileInterface";

interface MessagesListProps {
  profiles: SlimProfile[];
  messageItemClicked: (profile: SlimProfile) => void;
}

const MessagesList: React.FC<MessagesListProps> = ({
  profiles,
  messageItemClicked,
}) => {
  return (
    <>
      <div className={classes.messages_list__text}>Messages</div>
      <div className={classes.messages_list__items}>
        {profiles.map((profile, index) => (
          <MessagesListItem
            key={index}
            profile={profile}
            messageItemClicked={messageItemClicked}
          />
        ))}
      </div>
    </>
  );
};

export default MessagesList;
