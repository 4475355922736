import { RelationshipProps } from "../interfaces/relationshipInterface";

interface FilterProps {
  relationships: RelationshipProps[];
  idIndex: string[];
  [key: string]: any;
}

const filterNotAcceptedRelationships = ({
  relationships,
  idIndex,
}: FilterProps): [RelationshipProps[], string[]] => {
  const filteredIdIndex: string[] = [];
  const filteredRelationships: RelationshipProps[] = relationships.filter(
    (relationship: RelationshipProps, index) => {
      if (relationship?.accepted) {
        filteredIdIndex.push(idIndex[index]);
      }
      return relationship?.accepted;
    }
  );

  return [filteredRelationships, filteredIdIndex];
};

export default filterNotAcceptedRelationships;
