import { useNavigate } from "react-router-dom";
import classes from "./Prefs.module.css";
import { HandPalm, FirstAid, Phone, ArrowLeft } from "@phosphor-icons/react";
import { useState } from "react";
import ToggleSwitch from "./ToggleSwitch";

const PrivacySafetyPrefs = (props: any) => {
  const handleChange = () => {};
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.prefs__arrow}>
        <ArrowLeft size={32} onClick={props.closeSlider} />
      </div>
      <div className={classes.prefs__wrapper}>
        <div className={classes.prefs__title}>Privacy & Safety Preferences</div>
        <div className={classes.prefs__section}>Visibility</div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>
            Hide me from Search Results
          </div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>
            Hide my online status & login date
          </div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>Hide profile views</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__section}>Video Calls</div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>Block incoming video call</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>Calls from contacts only</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__section}>Dating Safely</div>
        <div className={classes.prefs__item}>
          <div
            className={classes.prefs__label_lower}
            onClick={() => {
              props.linkOut();
              navigate("/blocked");
            }}
          >
            Blocked Members List
          </div>
          <HandPalm size={40} />
        </div>
        <div className={classes.prefs__item}>
          <div
            className={classes.prefs__label_lower}
            onClick={() => {
              props.linkOut();
              navigate("/report_abuse");
            }}
          >
            Reporting Abuse
          </div>
          <Phone size={40} />
        </div>
        <div className={classes.prefs__item}>
          <div
            className={classes.prefs__label_lower}
            onClick={() => {
              props.linkOut();
              navigate("/safety_tips");
            }}
          >
            Safe Dating Tips
          </div>
          <FirstAid size={40} />
        </div>
      </div>
    </>
  );
};

export default PrivacySafetyPrefs;
