import classes from "./OfferSentConfirmModal.module.css";
import { Link } from "react-router-dom";
import { ProfileProps } from "../../interfaces/profileInterface";

interface OfferSentConfirmModalProps {
  profile: ProfileProps;
  amount?: number;
  closeModal: (amount: number) => void;
}

const OfferSentConfirmModal: React.FC<OfferSentConfirmModalProps> = ({
  profile,
  amount,
  closeModal,
}) => {
  console.log("Entering Offer Sent Confirm Modal", profile, amount);

  const closeModalLocal = () => {
    closeModal(amount || 0);
  };
  return (
    <div className={classes.offer_sent__wrapper}>
      <div className={classes.offer_sent__top}>
        <div className={classes.offer_sent__photo_wrapper}>
          <img
            className={classes.offer_sent__photo}
            src={profile?.photoUrl?.[0]}
            alt="user"
          />
        </div>
        <div className={classes.offer_sent__amount}>${amount} Offer Sent</div>
      </div>
      <div className={classes.offer_sent__label}>
        Your offer to {profile?.screenName} has been sent.
      </div>
      <button
        className={classes.offer_sent__close_button}
        onClick={closeModalLocal}
      >
        Close
      </button>
      <div className={classes.offer_sent__footer__text}>
        <Link to="/">Read Safe Dating Tips</Link>&nbsp;|&nbsp;
        <Link to="/">Read Community Guidelines</Link>
      </div>
    </div>
  );
};

export default OfferSentConfirmModal;
