import React, { useContext } from "react";
import { GlobalContent } from "../interfaces/globalContent";
import AuthContext from "../store/auth-context";

import Offers from "../components/Offers/_Offers";

const OffersPage = () => {
  const ctx = useContext<GlobalContent>(AuthContext);
  if (ctx.isLoggedIn) {
    return <Offers />;
  } else {
    return <div></div>;
  }
};

export default OffersPage;
