import classes from "./Cards.module.css";
import Card from "./Card";
import { ProfileProps } from "../../interfaces/profileInterface";

interface CardsProps {
  profiles: ProfileProps[];
  type: string;
}

const Cards: React.FC<CardsProps> = ({ profiles, type }) => {
  console.log(Object.entries(profiles));
  return (
    <div className={classes.cards}>
      {profiles.map((profile, index) => (
        <Card key={index} profile={profile} type={type} />
      ))}
    </div>
  );
};

export default Cards;
