import React, { useContext } from "react";
import { GlobalContent } from "../interfaces/globalContent";
import AuthContext from "../store/auth-context";

import Messages from "../components/Messages/_Messages";

const MessagesPage = () => {
  const ctx = useContext<GlobalContent>(AuthContext);
  if (ctx.isLoggedIn) {
    return <Messages />;
  } else {
    return <div></div>;
  }
};

export default MessagesPage;
