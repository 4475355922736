import { ProfileProps } from "../interfaces/profileInterface";
import getCurrentTimestamp from "./getCurrentTimestamp";
import saveRelationship from "./saveRelationship";
import { RelationshipProps } from "../interfaces/relationshipInterface";

interface saveNewOfferProps {
  profile: ProfileProps;
  amount: number;
}

const saveNewOffer = ({
  profile,
  amount,
}: saveNewOfferProps): Promise<void> => {
  console.log("Save Offer: ", profile, amount);
  // Create relationship and set initial offer amount
  const newRelationship: RelationshipProps = {
    offererId: localStorage.getItem("profileId") || "",
    offereeId: profile.id || "",
    accepted: false,
    conv: [],
    offers: [
      {
        amount: amount,
        timestamp: getCurrentTimestamp(),
        accepted: false,
      },
    ],
    dateCreated: getCurrentTimestamp(),
    dateConvUpdated: "",
    dateOffersUpdated: getCurrentTimestamp(),
  };

  return saveRelationship(newRelationship);
};

export default saveNewOffer;
