import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Assuming you have the Firebase instance initialized

interface User {
  email: string;
  id: string;
  photoUrl: string[];
  screenName: string;
  // Add other fields of the User object here
}

async function getProfileByEmail(email: string): Promise<User | null> {
  try {
    const usersCollection = collection(db, "users");

    const q = query(usersCollection, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null; // Return null if no user with the given email is found
    }

    const userData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as User[];

    // Assuming email is unique, there should be only one matching document
    const userProfile = userData[0];
    return userProfile;
  } catch (error) {
    console.error("Error retrieving user:", error);
    throw error;
  }
}

export default getProfileByEmail;
