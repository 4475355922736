import React, { useRef, useState } from "react";
import classes from "./OfferBox.module.css";
import saveNewOffer from "../../helpers/saveNewOffer";
import { Modal, ModalBody } from "reactstrap";
import { ProfileProps } from "../../interfaces/profileInterface";
import { RelationshipProps } from "../../interfaces/relationshipInterface";
import OfferSentConfirmModal from "../widgets/OfferSentConfirmModal";

interface OfferBoxProps {
  profile: ProfileProps;
  closeModal?: (amount: number) => void;
  offerStatusChanged: (newStatus: string) => void;
}

const OfferBox: React.FC<OfferBoxProps> = ({
  profile,
  closeModal,
  offerStatusChanged,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const amountRef = useRef<HTMLInputElement>(null);

  const closeModalLocal = () => {
    setModalOpen(false);
  };

  const quickButton = (amount: number) => {
    console.log(amount);
    if (amountRef.current) {
      amountRef.current.value = `$${amount}`;
    }
  };

  const sendOffer = () => {
    console.log("AMOUNT: ", amountRef?.current?.value);
    const amountEntered = Number(
      amountRef?.current?.value.replace("$", "") || "0"
    );
    setAmount(amountEntered);
    console.log("Profile - Pre sendOrReceive check", profile);
    if (profile?.sendOrReceive === "receive") {
      saveNewOffer({ profile, amount: amountEntered }).then(() => {
        offerStatusChanged("myOfferPending");
        setModalOpen(true);
      });
    } else {
      alert("This user is not accepting offers at this time.");
    }
  };

  return (
    <>
      <div className={classes.offer_box}>
        <div className={classes.offer_box__title}>
          Send {profile.screenName} a date offer!
        </div>
        <input
          className={classes.offer_box__input}
          type="text"
          defaultValue="$"
          ref={amountRef}
        />
        <ul className={classes.offer_box__list}>
          <li
            className={classes.offer_box__list_item}
            onClick={() => quickButton(50)}
          >
            $50
          </li>
          <li
            className={classes.offer_box__list_item}
            onClick={() => quickButton(100)}
          >
            $100
          </li>
          <li
            className={classes.offer_box__list_item}
            onClick={() => quickButton(150)}
          >
            $150
          </li>
          <li
            className={classes.offer_box__list_item}
            onClick={() => quickButton(200)}
          >
            $200
          </li>
        </ul>
        <div className={classes.offer_box__text}>
          Maximum offer amount is $1000
        </div>
        <div className={classes.offer_box__button} onClick={sendOffer}>
          Send Offer
        </div>
      </div>
      <Modal
        className={classes.modal}
        isOpen={modalOpen}
        centered={true}
        backdrop={true}
        // toggle={closeModal}
      >
        <ModalBody>
          {modalOpen && (
            <OfferSentConfirmModal
              profile={profile}
              closeModal={closeModal ? closeModal : closeModalLocal}
              amount={amount}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default OfferBox;
