import React, { useEffect, useState } from "react";
import classes from "./OfferPendingBox.module.css";
import saveRelationship from "../../helpers/saveRelationship";
import { Modal, ModalBody } from "reactstrap";
import { ProfileProps } from "../../interfaces/profileInterface";
import { RelationshipProps } from "../../interfaces/relationshipInterface";
import OfferSentConfirmModal from "../widgets/OfferSentConfirmModal";
import getCurrentTimestamp from "../../helpers/getCurrentTimestamp";

interface OfferBoxProps {
  profile: ProfileProps;
  relationship?: RelationshipProps;
  closeModal?: (amount: number) => void;
  offerStatusChanged: (newStatus: string) => void;
}

const IPendingBox: React.FC<OfferBoxProps> = ({
  profile,
  relationship,
  closeModal,
  offerStatusChanged,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(
    relationship?.offers
      ? Number(relationship.offers[relationship.offers.length - 1].amount)
      : 0
  );

  useEffect(() => {
    if (relationship?.offers && relationship.offers.length > 0) {
      setAmount(
        Number(relationship.offers[relationship.offers.length - 1].amount)
      );
    } else {
      setAmount(0);
    }
  }, [relationship]);

  const closeModalLocal = () => {
    setModalOpen(false);
  };

  const acceptOffer = () => {
    console.log("acceptOffer", relationship);
    const relationshipOffers = relationship?.offers;

    if (relationshipOffers?.[relationshipOffers.length - 1].accepted) {
      console.log(
        "SYNC ERROR - CONTACT DEVELOPER:  TRYING TO ACCEPTED ALREADY ACCEPTED OFFER"
      );
      return;
    }

    relationshipOffers?.push({
      amount: amount,
      accepted: true,
      timestamp: getCurrentTimestamp(),
    });

    const newRelationship = {
      accepted: true,
      offers: relationshipOffers,
      offererId: relationship?.offererId,
      offereeId: relationship?.offereeId,
      dateOffersUpdated: getCurrentTimestamp(),
    };

    console.log("Saving relationship", newRelationship);

    saveRelationship(newRelationship).then(() =>
      offerStatusChanged("iAccepted")
    );
  };

  const counterOffer = () => {};

  return (
    <>
      <div className={classes.offer_pending_box}>
        <div className={classes.offer_pending_box__title}>
          {profile.screenName} made you an offer!
        </div>
        <div className={classes.offer_pending_box__subtitle}>
          Click Offer Amount to edit your offer.
        </div>
        <div className={classes.offer_pending_box__amount_wrapper}>
          <div className={classes.offer_pending_box__amount}>${amount}</div>
          <div className={classes.offer_pending_box__subtitle}>
            Offer Amount
          </div>
        </div>
        <div className={classes.offer_pending_box__button_box}>
          <div
            className={classes.offer_pending_box__button}
            onClick={counterOffer}
          >
            Counter Offer
          </div>
          <div
            className={classes.offer_pending_box__button}
            onClick={acceptOffer}
          >
            Accept Offer
          </div>
        </div>
        {relationship?.id}
      </div>
      <Modal
        className={classes.modal}
        isOpen={modalOpen}
        centered={true}
        backdrop={true}
        // toggle={closeModal}
      >
        <ModalBody>
          {modalOpen && (
            <OfferSentConfirmModal
              profile={profile}
              closeModal={closeModal ? closeModal : closeModalLocal}
              amount={amount}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default IPendingBox;
