import { useState, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { GlobalContent } from "../../interfaces/globalContent";

import classes from "./PrefsMain.module.css";
import {
  XCircle,
  PencilSimple,
  Info,
  Gear,
  ShieldCheckered,
  BellRinging,
  SignOut,
  Password,
} from "@phosphor-icons/react";
import PrefsPanel from "./PrefsPanel";
import { useNavigate } from "react-router-dom";

import HelpCenter from "./HelpCenter";
import NotificationPrefs from "./NotificationPrefs";
import PrivacySafetyPrefs from "./PrivacySafetyPrefs";
import AccountPrefs from "./AccountPrefs";

const PrefsMain = (props: any) => {
  const [subPanelIsOpen, setSubPanelIsOpen] = useState(false);
  const [subPanel, setSubPanel] = useState("");

  const navigate = useNavigate();
  const ctx = useContext<GlobalContent>(AuthContext);

  const toProfile = () => {
    props.closeSlider();
    navigate("/profile");
  };

  const toPassword = () => {
    props.closeSlider();
    navigate("/password");
  };

  const logout = () => {
    props.closeSlider();
    ctx.logout();
  };

  const toHelpCenter = () => {
    setSubPanel("help");
    setSubPanelIsOpen(true);
  };

  const toAccountPrefs = () => {
    setSubPanel("account");
    setSubPanelIsOpen(true);
  };

  const toNotificationPrefs = () => {
    setSubPanel("notifications");
    setSubPanelIsOpen(true);
  };

  const toPrivacyPrefs = () => {
    setSubPanel("privacy");
    setSubPanelIsOpen(true);
  };

  const linkOut = () => {
    console.log("Link Out");
    setSubPanelIsOpen(false);
    props.closeSlider();
  };

  return (
    <div className={classes.prefs_main__wrapper}>
      <PrefsPanel isOpen={subPanelIsOpen} onClose={linkOut}>
        {subPanel === "help" && (
          <HelpCenter
            closeSlider={() => setSubPanelIsOpen(false)}
            linkOut={linkOut}
          />
        )}
        {subPanel === "account" && (
          <AccountPrefs
            closeSlider={() => setSubPanelIsOpen(false)}
            linkOut={linkOut}
          />
        )}
        {subPanel === "privacy" && (
          <PrivacySafetyPrefs
            closeSlider={() => setSubPanelIsOpen(false)}
            linkOut={linkOut}
          />
        )}
        {subPanel === "notifications" && (
          <NotificationPrefs
            closeSlider={() => setSubPanelIsOpen(false)}
            linkOut={linkOut}
          />
        )}
      </PrefsPanel>
      <div className={classes.prefs_main__item_right}>
        <XCircle size={32} onClick={props.closeSlider} color={"grey"} />
      </div>
      <div className={classes.prefs_main__avatar_frame}>
        <img
          className={classes.prefs_main__avatar}
          src={localStorage.getItem("avatar") || "./images/missing-male.png"}
          alt="avatar"
        />
      </div>
      <div
        className={classes.prefs_main__item}
        style={{ paddingBottom: "0px" }}
      >
        <div className={classes.prefs_main__screen_name}>
          {localStorage.getItem("screenName")}
        </div>
      </div>
      <div className={classes.prefs_main__edit_wrapper}>
        <div className={classes.prefs_main__edit_profile} onClick={toProfile}>
          <PencilSimple size={20} />
          Edit
        </div>
      </div>
      <div className={classes.prefs_main__item} onClick={toPassword}>
        <div className={classes.prefs_main__div}>Password</div>
        <Password size={32} color={"grey"} />
      </div>
      <div className={classes.prefs_main__item} onClick={toNotificationPrefs}>
        <div className={classes.prefs_main_div}>Notifications</div>
        <BellRinging size={32} color={"grey"} />
      </div>
      <div className={classes.prefs_main__item} onClick={toPrivacyPrefs}>
        <div className={classes.prefs_main_div}>Privacy & Safety</div>
        <ShieldCheckered size={32} color={"grey"} />
      </div>
      <div className={classes.prefs_main__item} onClick={toAccountPrefs}>
        <div className={classes.prefs_main_div}>Account</div>
        <Gear size={32} color={"grey"} />
      </div>
      <div className={classes.prefs_main__item} onClick={toHelpCenter}>
        <div className={classes.prefs_main_div}>Help Center</div>
        <Info size={32} color={"grey"} />
      </div>
      <div className={classes.prefs_main__item}>
        <div className={classes.prefs_main_div} onClick={logout}>
          Log Out
        </div>
        <SignOut size={32} color={"grey"} />
      </div>
    </div>
  );
};

export default PrefsMain;
