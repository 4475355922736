import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import classes from "./_Detail.module.css";
import { useLocation } from "react-router-dom";

import { ProfileProps } from "../../interfaces/profileInterface";
import { AboutMeProps } from "../../interfaces/aboutMeInterface";
import { RelationshipProps } from "../../interfaces/relationshipInterface";

import saveProfileById from "../../helpers/saveProfileById";
import addItemToArray from "../../helpers/addItemToArray";
import getCurrentTimestamp from "../../helpers/getCurrentTimestamp";

import getBucketLinks from "../../helpers/getBucketLinks";
import deleteAllProfiles from "../../helpers/deleteAllProfiles";
import updateAllProfiles from "../../helpers/updateAllProfiles";

import DetailBox from "./DetailBox";
import DetailBoxEdit from "./DetailBoxEdit";
import Gallery from "./Gallery";
import Description from "./Description";
import DescriptionEdit from "./DescriptionEdit";
import IdealFirstDate from "./IdealFirstDate";
import IdealFirstDateEdit from "./IdealFirstDateEdit";

import AboutMe from "./AboutMe";
import AboutMeEdit from "./AboutMeEdit";
import Interests from "./Interests";
import InterestsEdit from "./InterestsEdit";

import Upload from "./Upload";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import getProfileById from "../../helpers/getProfileById";
import getRelationshipByPair from "../../helpers/getRelationshipByPair";
import getRelationshipsByOfferee from "../../helpers/getRelationshipsByOfferee";
import ActivationEmailSentModal from "../Auth/ActivationEmailSentModal";

interface DetailProps {
  editButtonsPresent: boolean;
  recordId: string;
  offerBoxPresent: any;
}

const Detail: React.FC<DetailProps> = (props) => {
  const location = useLocation();
  const { id } = useParams();

  const editButtonsPresent = location?.state?.edit || props.editButtonsPresent;

  const [description, setDescription] = useState<string>("");
  const [ifd, setIfd] = useState<string>("");
  const [aboutMe, setAboutMe] = useState<AboutMeProps>({
    lookingFor: "",
    bodyType: "",
    height: "",
    weight: "",
    hasChildren: "",
    smoking: "",
    drinking: "",
    education: "",
    occupation: "",
    income: "",
  });
  const [interests, setInterests] = useState<string[]>([]);
  const [editingDetailBox, setEditingDetailBox] = useState<boolean>(false);
  const [editingDescription, setEditingDescription] = useState<boolean>(false);
  const [editingIfd, setEditingIfd] = useState<boolean>(false);
  const [editingAboutMe, setEditingAboutMe] = useState<boolean>(false);
  const [editingInterests, setEditingInterests] = useState<boolean>(false);
  const [photoUrls, setPhotoUrls] = useState<string[]>([]);
  const [detailBox, setDetailBox] = useState<string>();
  const [profileData, setProfileData] = useState<ProfileProps>();
  const [relationship, setRelationship] = useState<RelationshipProps>(
    {} as RelationshipProps
  );

  const myId = localStorage.getItem("profileId") || "";

  useEffect(() => {
    const getRelationship = async () => {
      if (profileData?.id && myId && myId !== profileData?.id) {
        console.log("Getting relationship");
        const data = await getRelationshipByPair(profileData?.id || "", myId);
        setRelationship(data[0][0]);
        console.log("Relationship Data - Detail:", data);
      }
    };

    getRelationship();
  }, [profileData]);

  useEffect(() => {
    const myId = localStorage.getItem("profileId") || "";

    // This is the case where the user has navigated directly to the detail page
    if (location?.state?.card) {
      const cardData = location?.state?.card;
      createInitialProfileData(cardData);
      saveVisitedMeInfo(cardData, myId);
    } else {
      const myId = localStorage.getItem("profileId") || "";
      console.log("DETAIL useEffect - id:", id, " myId: ", myId);

      getProfileById(id || props.recordId)
        .then((user) => {
          if (user) {
            createInitialProfileData(user);
            saveVisitedMeInfo(user, myId);
          } else {
            console.log("User ", id, "not found in _Details");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // updateAllProfiles();
    // deleteAllProfiles();
    // getBucketLinks();
  }, []);

  const saveVisitedMeInfo = (data: ProfileProps, myId: string) => {
    if (data?.id === myId) {
      return;
    }
    console.log("Visted Me Info", data);

    const tempArray = addItemToArray(data?.visitedMe || [], myId);
    const tempArrayTimestamp = addItemToArray(
      data?.visitedMeTimestamp || [],
      getCurrentTimestamp()
    );

    data.visitedMe = tempArray;
    data.visitedMeTimestamp = tempArrayTimestamp;
    console.log("Saving Visitor Info", data);
    saveProfileById(data);
    setProfileData(data);
  };

  const createInitialProfileData = (data: ProfileProps | undefined) => {
    setProfileData(data);
    setDescription(data?.description || "");
    setIfd(data?.ifd || "");
    setAboutMe({
      lookingFor: data?.lookingFor || "",
      bodyType: data?.bodyType || "",
      height: data?.height || "",
      weight: data?.weight || "",
      hasChildren: data?.hasChildren || "",
      smoking: data?.smoking || "",
      drinking: data?.drinking || "",
      education: data?.education || "",
      occupation: data?.occupation || "",
      income: data?.income || "",
    });
    setInterests(data?.interests || []);
    setPhotoUrls(data?.photoUrl || []);
  };

  const saveDetailBoxHandler = (detailBox: string) => {
    setDetailBox(detailBox);
    setEditingDetailBox(false);
  };

  const saveDescriptionHandler = (description: string) => {
    setDescription(description);
    setEditingDescription(false);

    if (profileData) {
      profileData.description = description || "";
      saveProfileById(profileData);
    }
  };

  const saveIfdHandler = (ifd: string) => {
    setIfd(ifd);
    setEditingIfd(false);

    if (profileData) {
      profileData.ifd = ifd || "";
      saveProfileById(profileData);
    }
  };

  const saveAboutMeHandler = (aboutMe: AboutMeProps) => {
    console.log("Save aboutMe: ", aboutMe);
    setAboutMe(aboutMe);
    setEditingAboutMe(false);

    if (profileData) {
      profileData.lookingFor = aboutMe.lookingFor || "";
      profileData.bodyType = aboutMe.bodyType || "";
      profileData.height = aboutMe.height || "";
      profileData.weight = aboutMe.weight || "";
      profileData.hasChildren = aboutMe.hasChildren || "";
      profileData.smoking = aboutMe.smoking || "";
      profileData.drinking = aboutMe.drinking || "";
      profileData.education = aboutMe.education || "";
      profileData.occupation = aboutMe.occupation || "";
      profileData.income = aboutMe.income || "";
      saveProfileById(profileData);
    }
  };

  const saveInterestsHandler = (interests: string[]) => {
    setInterests(interests);
    setEditingInterests(false);

    if (profileData) {
      profileData.interests = interests;
      saveProfileById(profileData);
    }
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        setModalOpen(false);
      }}
      type="button"
    >
      &times;
    </button>
  );

  const newPhotosHandler = (newPhotos: string[]) => {
    setModalOpen(false);
    setPhotoUrls((prevPhotoUrls) => [...prevPhotoUrls, ...newPhotos]);

    const myId = localStorage.getItem("profileId") || "";
    const thisProfileId = profileData?.id || "";
    console.log("thisProfileId", thisProfileId, "myId", myId);

    if (thisProfileId === myId) {
      console.log("Saving new photos", newPhotos);
      saveProfileById({
        photoUrl: [...photoUrls, ...newPhotos],
        id: myId,
      });

      setProfileData((prevState) => ({
        ...prevState,
        photoUrl: [...photoUrls, ...newPhotos],
      }));

      const avatar =
        [...photoUrls, ...newPhotos][0] || "./images/missing-male.png";

      localStorage.setItem("userAvatar", avatar);
      console.log(
        "src: ",
        document?.getElementById("avatar-header")?.getAttribute("src")
      );
      document?.getElementById("avatar-header")?.setAttribute("src", avatar);
    } else {
      console.log("Can't save new photos - id's don't match.");
    }
  };

  return (
    <div className={classes.detail}>
      <div className={classes.detail_page__left}>
        {editingDetailBox ? (
          <DetailBoxEdit
            profileData={profileData}
            editButtonsPresent={editButtonsPresent}
            editMode={editingDetailBox}
            offerBoxPresent={props.offerBoxPresent}
            handleEditClick={() => setEditingDetailBox(false)}
            cancelHandler={() => setEditingDetailBox(false)}
            saveHandler={saveDetailBoxHandler}
          />
        ) : (
          <DetailBox
            profileData={profileData}
            editButtonsPresent={editButtonsPresent}
            editMode={editingDetailBox}
            offerBoxPresent={props.offerBoxPresent}
            handleEditClick={() => setEditingDetailBox(true)}
            cancelHandler={() => setEditingDetailBox(false)}
            saveHandler={saveDetailBoxHandler}
            relationshipEntry={relationship}
          />
        )}
        <Gallery
          data={photoUrls}
          editButtonsPresent={editButtonsPresent}
          onClick={() => setModalOpen(true)}
        />
        {editingDescription ? (
          <DescriptionEdit
            aboutMe={description || ""}
            cancelHandler={() => setEditingDescription(false)}
            saveHandler={saveDescriptionHandler}
          />
        ) : (
          <Description
            aboutMe={description}
            editButtonsPresent={editButtonsPresent}
            handleEditClick={() => setEditingDescription(true)}
          />
        )}
        {editingIfd ? (
          <IdealFirstDateEdit
            ifd={ifd || ""}
            cancelHandler={() => setEditingIfd(false)}
            saveHandler={saveIfdHandler}
          />
        ) : (
          <IdealFirstDate
            idealFirstDate={ifd}
            editButtonsPresent={editButtonsPresent}
            handleEditClick={() => setEditingIfd(true)}
          />
        )}
      </div>
      <div className={classes.detail_page__right}>
        {editingAboutMe ? (
          <AboutMeEdit
            aboutMe={aboutMe}
            cancelHandler={() => setEditingAboutMe(false)}
            saveHandler={saveAboutMeHandler}
          />
        ) : (
          <AboutMe
            aboutMe={aboutMe}
            editButtonsPresent={editButtonsPresent}
            handleEditClick={() => setEditingAboutMe(true)}
          />
        )}
        {editingInterests ? (
          <InterestsEdit
            interests={interests}
            cancelHandler={() => setEditingInterests(false)}
            saveHandler={saveInterestsHandler}
          />
        ) : (
          <Interests
            interests={interests || []}
            editButtonsPresent={editButtonsPresent}
            handleEditClick={() => setEditingInterests(true)}
          />
        )}
      </div>
      <Modal
        className={classes.modal}
        isOpen={modalOpen}
        // centered={true}
        backdrop={true}
      >
        <ModalHeader className={classes.modal_header} close={closeBtn}>
          Upload photos
        </ModalHeader>
        <ModalBody>
          <Upload onUpload={newPhotosHandler} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Detail;
