import { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Prefs.module.css";
import ToggleSwitch from "./ToggleSwitch";
import { ArrowLeft } from "@phosphor-icons/react";

const NotificationPrefs = (props: any) => {
  const handleChange = (event: any) => {};
  const [checked, setChecked] = useState(false);

  return (
    <>
      <ArrowLeft
        size={40}
        onClick={props.closeSlider}
        className={classes.prefs__arrow}
      />
      <div className={classes.prefs__wrapper}>
        <div className={classes.prefs__header}>
          <div className={classes.prefs__title}>Notification Preferences</div>
        </div>
        <div className={classes.prefs__section}>App Notifications</div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>Getting Favorited</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>New Offers</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>New Messages</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__section}>Email Me</div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>New Members</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>Favorites</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>New Messages</div>
          <ToggleSwitch />
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>Getting Favorited</div>
          <ToggleSwitch />
        </div>
      </div>
    </>
  );
};

export default NotificationPrefs;
