import React, { useContext } from "react";
import { GlobalContent } from "../interfaces/globalContent";
import AuthContext from "../store/auth-context";

import Search from "../components/Search/_Search";

const SearchPage = () => {
  const ctx = useContext<GlobalContent>(AuthContext);
  if (ctx.isLoggedIn) {
    return <Search />;
  } else {
    return <div></div>;
  }
};

export default SearchPage;
