import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import classes from "./_Home.module.css";
import Cards from "../widgets/Cards";
import Activity from "./Activity";
import genFakeProfile from "../../helpers/genFakeProfile";
import sortParallelArrays from "../../helpers/sortParallelArrays";
import getProfileById from "../../helpers/getProfileById";
import getProfilesByIds from "../../helpers/getProfilesByIds";
import getRelationshipsByIds from "../../helpers/getRelationshipsByIds";
import { ProfileProps } from "../../interfaces/profileInterface";

const Home = () => {
  const [visitedMeProfiles, setVisitedMeProfiles] = useState<ProfileProps[]>(
    []
  );
  const [favoritedMeProfiles, setFavoritedMeProfiles] = useState<
    ProfileProps[]
  >([]);
  const [favoritesProfiles, setFavoritesProfiles] = useState<ProfileProps[]>(
    []
  );

  useEffect(() => {
    const fetchProfiles = async () => {
      const myProfile = await getProfileById(
        localStorage.getItem("profileId") || ""
      );
      if (myProfile) {
        console.log("MyProfile", myProfile);
        let visitedMe = myProfile.visitedMe || [];
        let visitedMeTimestamp = myProfile.visitedMeTimestamp || [];
        [visitedMeTimestamp, visitedMe] = sortParallelArrays(
          visitedMeTimestamp,
          visitedMe
        );

        let favoritedMe = myProfile.favoritedMe || [];
        let favoritedMeTimestamp = myProfile.favoritedMeTimestamp || [];
        [favoritedMeTimestamp, favoritedMe] = sortParallelArrays(
          favoritedMeTimestamp,
          favoritedMe
        );

        let favorites = myProfile.favorites || [];
        let favoritesTimestamp = myProfile.favoritesTimestamp || [];
        [favoritesTimestamp, favorites] = sortParallelArrays(
          favoritesTimestamp,
          favorites
        );

        const visitedMeProfiles = await getProfilesByIds(visitedMe);
        const visitedMeRelationships = await getRelationshipsByIds(visitedMe);
        const favoritedMeProfiles = await getProfilesByIds(favoritedMe);
        const favoritesProfiles = await getProfilesByIds(favorites);

        setVisitedMeProfiles(visitedMeProfiles);
        setFavoritedMeProfiles(favoritedMeProfiles);
        setFavoritesProfiles(favoritesProfiles);

        console.log("Relationships:", visitedMeRelationships);
      }
    };

    fetchProfiles();
  }, []);

  return (
    <div className={classes.home}>
      <div className={classes.home__title}>Your Activity</div>
      <Activity />
      <div className={classes.home__title}>My Favorites</div>
      <Cards profiles={favoritesProfiles.slice(0, 10)} type="standard" />
      {favoritesProfiles.length > 12 && (
        <Link to="/interests" className={classes.home__view_more}></Link>
      )}
      <div className={classes.home__title}>Viewed Me</div>
      <Cards profiles={visitedMeProfiles.slice(0, 10)} type="standard" />{" "}
      {visitedMeProfiles.length > 12 && (
        <Link to="/search" className={classes.home__view_more}>
          View More
        </Link>
      )}
      <div className={classes.home__title}>Favorited Me</div>
      <Cards profiles={favoritedMeProfiles.slice(0, 10)} type="standard" />{" "}
      <Link to="/search" className={classes.home__view_more}></Link>
    </div>
  );
};

export default Home;
