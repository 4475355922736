import React, { useState } from "react";

import { connect, useSelector } from "react-redux";
import "./App.css";
import Header from "./components/Header/Header";
import { Route, Routes } from "react-router-dom";

import Search from "./pages/search";
import Interests from "./pages/interests";
import Offers from "./pages/offers";
import Messages from "./pages/messages";
import Detail from "./pages/detail";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Password from "./pages/password";
import Profile from "./pages/profile";
import LandingPage from "./pages/landingPage";
import Home from "./pages/home";
import ReportAbuse from "./pages/reportAbuse";
import SafetyTips from "./pages/safetyTips";
import BlockedProfiles from "./pages/blockedProfiles";
import BuyCredits from "./pages/buyCredits";
import ContactUs from "./pages/contactUs";
import GDPR from "./pages/gdpr";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfUse from "./pages/termsOfUse";
import Faq from "./pages/faqs";
import CommunityGuidelines from "./pages/communityGuidelines";

import PrefsPanel from "./components/PrefsPanel/PrefsPanel";
import NotificationPrefs from "./components/PrefsPanel/NotificationPrefs";
import PrefsMain from "./components/PrefsPanel/PrefsMain";

function App() {
  const state = useSelector((state: any) => state);
  const [isOpen, setIsOpen] = useState(false);
  // console.log("State: ", state);

  return (
    <div className="App">
      <Header openPrefs={() => setIsOpen(true)} />
      <PrefsPanel isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PrefsMain
          closeSlider={() => {
            console.log("closeSlider from App");
            setIsOpen(false);
          }}
        />
      </PrefsPanel>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/password" element={<Password />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/search" element={<Search />} />
        <Route path="/interests" element={<Interests />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/detail/:id" element={<Detail />} />
        <Route path="/home" element={<Home />} />
        <Route path="/report_abuse" element={<ReportAbuse />} />
        <Route path="/safety_tips" element={<SafetyTips />} />
        <Route path="/blocked" element={<BlockedProfiles />} />
        <Route path="/buy_credits" element={<BuyCredits />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/gdpr" element={<GDPR />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_of_use" element={<TermsOfUse />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/community_guidelines" element={<CommunityGuidelines />} />

        <Route path="*" element={<LandingPage />} />
      </Routes>
    </div>
  );
}

const mapStateToProps = (store: any) => store;

export default connect(mapStateToProps)(App);
