import classes from "./InterestsGrid.module.css";
import InterestsCard from "./InterestsCard";
import { ProfileProps } from "../../interfaces/profileInterface";

interface GridProps {
  profiles: ProfileProps[];
  type: string;
  relationshipIds?: any;
  fetchProfiles?: () => void;
}

const InterestsGrid: React.FC<GridProps> = (props) => {
  console.log("props", props);
  const titles = {
    viewed_me: "Viewed Me",
    likes_me: "Favorited Me",
    favorites: "My Favorites",
  };
  let title = titles[props.type as keyof typeof titles] || "";

  return (
    <div className={classes.grid}>
      <div className={classes.grid__text}>{title}</div>
      <div className={classes.grid__cards}>
        {props.profiles.map((profile) => (
          <InterestsCard
            key={profile?.id || ""}
            type={props.type}
            profile={profile}
            offerHistory={
              (props.relationshipIds &&
                props.relationshipIds[profile.id || ""]?.[1]) ||
              0
            }
            relationshipId={
              (props.relationshipIds &&
                props.relationshipIds[profile.id || ""]?.[0]) ||
              0
            }
          />
        ))}
      </div>
    </div>
  );
};

export default InterestsGrid;
