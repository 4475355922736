const sortParallelArrays = (arrayA: any, arrayB: any) => {
  // Combine Array A and Array B into a single array of objects
  const combinedArray = arrayA.map((value: any, index: any) => ({
    a: value,
    b: arrayB[index],
  }));

  // Sort the combined array based on the values in Array A
  combinedArray.sort((itemA: any, itemB: any) => itemA.a - itemB.a);

  // Separate the sorted values back into Array A and Array B
  const sortedArrayA = combinedArray.map((item: any) => item.a);
  const sortedArrayB = combinedArray.map((item: any) => item.b);

  return [sortedArrayA, sortedArrayB];
};

export default sortParallelArrays;
