import { useRef, useContext } from "react";
import classes from "./PasswordForm.module.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const PasswordForm = () => {
  const newPasswordInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    console.log("ID TOKEN: ");
    console.log(authContext);

    const enteredNewPassword = newPasswordInputRef.current?.value;
    console.log("NEW P/W: " + enteredNewPassword);

    // TODO add validation
    fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:update?key=" +
        process.env.REACT_APP_FIREBASE_API_KEY,
      {
        method: "POST",
        body: JSON.stringify({
          idToken: authContext.token,
          password: enteredNewPassword,
          returnSecureToken: true,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        alert("Successfully changed password.");
        console.log(res);
        navigate("/");
      })
      .catch((err) => {
        alert("ERROR: " + err.message);
      });
  };

  return (
    <>
      {authContext.isLoggedIn ? (
        <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor="new-password">New Password</label>
            <input
              type="password"
              id="new-password"
              minLength={8}
              ref={newPasswordInputRef}
            />
          </div>
          <div className={classes.action}>
            <button>Change Password</button>
          </div>
        </form>
      ) : (
        <h1 style={{ marginTop: "130px" }}>
          You must be logged in to access your profile
        </h1>
      )}
    </>
  );
};

export default PasswordForm;
