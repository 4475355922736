import React, { useState } from "react";
import classes from "./DetailBox.module.css";
import OfferBox from "./OfferBox";
import EditButton from "../widgets/EditButton";
import { ProfileProps } from "../../interfaces/profileInterface";
import { Cross, Crosshair } from "@phosphor-icons/react";

type DetailBoxEditProps = {
  profileData: ProfileProps | undefined;
  editButtonsPresent: boolean;
  editMode: boolean;
  offerBoxPresent: boolean;
  handleEditClick: () => void;
  saveHandler: (nameLocation: string) => void;
  cancelHandler: () => void;
};

const DetailBoxEdit: React.FC<DetailBoxEditProps> = ({
  profileData,
  editButtonsPresent,
  editMode,
  offerBoxPresent,
  handleEditClick,
  saveHandler,
  cancelHandler,
}) => {
  const [toggleEdit, getToggleEdit] = useState<boolean>(false);
  return (
    <div className={classes.detail_box}>
      <div className={classes.detail_box__left}>
        <div className={classes.detail_box}>
          <img
            className={classes.detail_box__image}
            src={profileData?.photoUrl?.[0] || "./images/missing-male.png"}
            alt="some alt"
          />
        </div>
      </div>
      <div className={classes.detail_box__right}>
        <div className={classes.detail_box__name_bar}>
          <div className={classes.detail_box__name}>
            {profileData?.screenName}
          </div>
          <div className={classes.detail_box__cancel} onClick={cancelHandler}>
            Cancel
          </div>
        </div>
        <div className={classes.detail_box__subtext}></div>
        <div className={classes.detail_box__subtext}></div>
        <form className={classes.detail_box__form}>
          <div className={classes.detail_box__form_item}>
            <label htmlFor="screen_name">Screen Name:</label>
            <input
              className={classes.detail_box__input}
              id="screen_name"
              type="text"
              defaultValue={profileData?.screenName}
            />
          </div>
          <div className={classes.detail_box__form_item}>
            <label htmlFor="location">Location:</label>
            <input
              className={classes.detail_box__input}
              id="location"
              type="text"
              defaultValue={profileData?.age}
            />
          </div>
        </form>
        <div
          className={classes.detail_box__save}
          onClick={() => saveHandler("test")}
        >
          Save
        </div>
      </div>
    </div>
  );
};

export default DetailBoxEdit;
