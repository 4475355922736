import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";

import classes from "./OfferButton.module.css";
import { CurrencyDollar, HeartStraight } from "@phosphor-icons/react";

import RequestDate from "./RequestDate";
import { ProfileProps } from "../../interfaces/profileInterface";
import OfferSentConfirmModal from "./OfferSentConfirmModal";

interface OfferButtonProps {
  profile: ProfileProps;
  amount?: number;
}

const OfferButton: React.FC<OfferButtonProps> = ({ profile, amount }) => {
  console.log("Entering Offer Button", profile);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const [offerAmount, setOfferAmount] = useState<number>(amount || 0);

  const onClick = () => {
    setModalOpen(true);
  };

  const closeModal = (amount: number) => {
    setModalOpen(false);
    setModalConfirm(false);
  };

  const confirmModal = (amount: number) => {
    setOfferAmount(offerAmount);
    setModalConfirm(true);
  };

  return (
    <>
      <div className={classes.offer_button__wrapper} onClick={onClick}>
        {amount === 0 && (
          <div>
            <div className={classes.offer_button__icon}>
              <CurrencyDollar size={28} />
            </div>
            <div className={classes.offer_button__label}>Make Offer</div>
          </div>
        )}
        {amount !== 0 && (
          <div className={classes.offer_button__offer_box}>
            <div className={classes.offer_button__offer_amount}>
              ${offerAmount?.toString()}
            </div>
            <div className={classes.offer_button__offer_label}>Offer Sent</div>
          </div>
        )}
      </div>
      <Modal
        className={classes.modal}
        isOpen={modalOpen}
        centered={true}
        backdrop={true}
        // toggle={closeModal}
      >
        <ModalBody>
          {modalConfirm && (
            <OfferSentConfirmModal
              profile={profile}
              closeModal={closeModal}
              amount={offerAmount}
            />
          )}
          {!modalConfirm && (
            <RequestDate profile={profile} confirmModal={confirmModal} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
export default OfferButton;
