import React, { useEffect, useState } from "react";
import classes from "./DetailBox.module.css";
import OfferBox from "./OfferBox";
import OfferPendingBox from "./OfferPendingBox";
import OfferAcceptedBox from "./OfferAcceptedBox";
import IAcceptedBox from "./IAcceptedBox";
import IPendingBox from "./IPendingBox";
import EditButton from "../widgets/EditButton";
import getRelationshipsByOfferee from "../../helpers/getRelationshipsByOfferee";
import getRelationshipByPair from "../../helpers/getRelationshipByPair";
import { ProfileProps } from "../../interfaces/profileInterface";
import { RelationshipProps } from "../../interfaces/relationshipInterface";
import FavoriteButton from "../widgets/FavoriteButton";

type DetailBoxProps = {
  profileData: ProfileProps | undefined;
  editButtonsPresent: boolean;
  editMode: boolean;
  offerBoxPresent: boolean;
  relationshipEntry: RelationshipProps;
  handleEditClick: () => void;
  saveHandler: (nameLocation: string) => void;
  cancelHandler: () => void;
};

const DetailBox: React.FC<DetailBoxProps> = ({
  profileData,
  editButtonsPresent,
  editMode,
  offerBoxPresent,
  handleEditClick,
  saveHandler,
  cancelHandler,
  relationshipEntry,
}) => {
  const [offerAmount, setOfferAmount] = useState<number>(0);
  const [favorite, setFavorite] = useState<boolean>(false);
  const [offerType, setOfferType] = useState<string>("none");
  const [relationship, setRelationship] =
    useState<RelationshipProps>(relationshipEntry);
  // {} as RelationshipProps
  const myId = localStorage.getItem("profileId") || "";

  const getRelationship = async () => {
    if (!profileData?.id || !myId || myId === profileData?.id) {
      return;
    }
    const data = await getRelationshipByPair(profileData?.id || "", myId);
    setRelationship(data[0][0]);
    console.log("Relationship Data - DetailBox:", data[0][0]);
  };

  useEffect(() => {
    getRelationship();
  }, [profileData]);

  useEffect(() => {
    const favorited =
      (profileData?.favoritedMe &&
        profileData.favoritedMe.includes(
          localStorage.getItem("profileId") || "0"
        )) ||
      false;

    setFavorite(favorited);
  }, [profileData?.favoritedMe]);

  useEffect(() => {
    console.log("Reached useEffect in DetailBox");
    const getOfferType = () => {
      console.log("Checking type of offer: ", relationship);
      if (!relationship?.offers || relationship?.offers?.length === 0) {
        setOfferType("none");
        console.log("none");
      } else if (
        relationship.offererId === myId &&
        !(relationship?.accepted || false)
      ) {
        setOfferType("myOfferPending");
        console.log("myOfferPending");
      } else if (
        relationship.offererId === myId &&
        (relationship?.accepted || false)
      ) {
        setOfferType("myOfferAccepted");
        console.log("myOfferAccepted");
      } else if (
        relationship.offereeId === myId &&
        (relationship?.accepted || false)
      ) {
        setOfferType("iAccepted");
        console.log("iAccepted");
      } else if (
        relationship.offereeId === myId &&
        !(relationship?.accepted || false)
      ) {
        setOfferType("iPending");
        console.log("iPending");
      }
    };

    getOfferType();
  }, [profileData, relationship]);

  if (!profileData) {
    return null; // Or render a loading state or fallback component
  }

  const offerStatusChanged = (newStatus: string) => {
    console.log("Reached offerStatusChanged");
    getRelationship();
    setOfferType(newStatus);
  };

  return (
    <div className={classes.detail_box}>
      <div className={classes.detail_box__left}>
        <div className={classes.detail_box}>
          <img
            className={classes.detail_box__image}
            src={profileData.photoUrl?.[0] || "./images/missing-male.png"}
            alt="some alt"
          />
        </div>
      </div>
      <div className={classes.detail_box__right}>
        <div className={classes.detail_box__right_top}>
          <div>
            <div className={classes.detail_box__name_bar}>
              <div className={classes.detail_box__name}>
                {profileData.screenName}
              </div>
              {editButtonsPresent ? (
                <EditButton editMode={false} onClick={handleEditClick} />
              ) : null}
            </div>
            <div className={classes.detail_box__subtext}>
              {profileData.age} • {profileData.location}
            </div>
            <div className={classes.detail_box__subtext}>
              Last seen {profileData.lastSeen} • {profileData.id}
            </div>
          </div>
          {offerBoxPresent && (
            <FavoriteButton
              id={profileData?.id || ""}
              favoritedMe={profileData.favoritedMe}
            />
          )}
        </div>

        {offerBoxPresent && offerType === "myOfferPending" && (
          <OfferPendingBox
            profile={profileData}
            relationship={relationship}
            offerStatusChanged={offerStatusChanged}
          />
        )}

        {offerBoxPresent && offerType === "myOfferAccepted" && (
          <OfferAcceptedBox profile={profileData} relationship={relationship} />
        )}

        {offerBoxPresent && offerType === "iPending" && (
          <IPendingBox
            profile={profileData}
            relationship={relationship}
            offerStatusChanged={offerStatusChanged}
          />
        )}

        {offerBoxPresent && offerType === "iAccepted" && (
          <IAcceptedBox profile={profileData} relationship={relationship} />
        )}

        {offerBoxPresent && offerType === "none" && (
          <OfferBox
            profile={profileData}
            offerStatusChanged={offerStatusChanged}
          />
        )}

        {!offerBoxPresent && (
          <div className={classes.detail_box__offer_box}>
            This space will hold registration progress tracker
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailBox;
