import React, { useContext } from "react";
import { GlobalContent } from "../interfaces/globalContent";
import AuthContext from "../store/auth-context";

import Home from "../components/Home/_Home";

const HomePage = () => {
  const ctx = useContext<GlobalContent>(AuthContext);
  if (ctx.isLoggedIn) {
    return <Home />;
  } else {
    return <div></div>;
  }
};

export default HomePage;
