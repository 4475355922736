import React, { useState } from "react";
import classes from "./IdealFirstDate.module.css";
import EditButton from "../widgets/EditButton";

interface IdealFirstDateProps {
  idealFirstDate: string;
  editButtonsPresent: boolean;
  handleEditClick: () => void;
}

const IdealFirstDate: React.FC<IdealFirstDateProps> = ({
  idealFirstDate,
  editButtonsPresent,
  handleEditClick,
}) => {
  const [toggleEdit, getToggleEdit] = useState<boolean>(false);

  const editHandler = () => {
    getToggleEdit(!toggleEdit);
    handleEditClick();
  };

  return (
    <div className={classes.ifd}>
      <div className={classes.ifd__title_bar}>
        <div className={classes.ifd__title}>Ideal First Date:</div>
        {editButtonsPresent ? (
          <EditButton editMode={toggleEdit} onClick={handleEditClick} />
        ) : null}
      </div>
      <div className={classes.ifd__text}>{idealFirstDate}</div>
    </div>
  );
};

export default IdealFirstDate;
