import React, { useState, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CreateAccountPart1 from "./CreateAccountPart1";

import classes from "./AuthForm.module.css";
import AuthContext from "../../store/auth-context";

const fetch_firebase = (
  url: string,
  enteredEmail: string | undefined,
  enteredPassword: string | undefined,
  setIsLoading: (isLoading: boolean) => void
) => {
  console.log("Fetching...");
  setIsLoading(true);
  const api_key = process.env.REACT_APP_FIREBASE_API_KEY; // "AIzaSyAVUHd3K5ThdnmaQc9hVsHsGaZs2Vsc5zs";
  return fetch(url + "?key=" + api_key, {
    method: "POST",
    body: JSON.stringify({
      email: enteredEmail,
      password: enteredPassword,
      returnSecureToken: true,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).finally(() => {
    setIsLoading(false);
  });
};

const AuthForm = (props: any) => {
  const navigate = useNavigate();

  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const passwordConfirmInputRef = useRef<HTMLInputElement>(null);

  const useLogIn = props.useLogIn;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const authContext = useContext(AuthContext);

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const enteredEmail = emailInputRef.current?.value;
    const enteredPassword = passwordInputRef.current?.value;
    const enteredPasswordConfirm = passwordConfirmInputRef.current?.value;

    if (!useLogIn && enteredPassword !== enteredPasswordConfirm) {
      alert("Passwords do not match!");
      return;
    }

    if (!enteredEmail) {
      alert("Email address is required.");
      return;
    }

    // Add more validation

    let url: string;
    if (useLogIn) {
      url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword";
    } else {
      url = "https://identitytoolkit.googleapis.com/v1/accounts:signUp";
    }
    fetch_firebase(url, enteredEmail, enteredPassword, setIsLoading)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = "Authentication failed!";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
            }
            console.log(data);
            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        const expirationTime = new Date(
          new Date().getTime() + +data.expiresIn * 1000
        );
        authContext.login(
          data.idToken,
          expirationTime.toISOString(),
          enteredEmail
        );
        useLogIn && navigate("/home");
        setModalOpen(true);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.message);
      });
  };

  return (
    <section className={classes.auth}>
      <h1>{useLogIn ? "Login" : "Sign Up"}</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.auth__form_item}>
          <label htmlFor="email">Your Email</label>
          <input type="email" id="email" ref={emailInputRef} required />
        </div>
        <div className={classes.auth__form_item}>
          <label htmlFor="password">Your Password</label>
          <input
            type="password"
            id="password"
            ref={passwordInputRef}
            required
          />
          {useLogIn ? null : (
            <div className={classes.auth__form_item}>
              <label htmlFor="password_confirm">Confirm Password</label>
              <input
                type="password"
                id="password_confirm"
                ref={passwordConfirmInputRef}
                required
              />
            </div>
          )}
        </div>
        <div className={classes.actions}>
          {!isLoading && (
            <button>{useLogIn ? "Login" : "Create Account"}</button>
          )}
          {isLoading && <button>Sending request</button>}
        </div>
        {useLogIn && (
          <div style={{ marginTop: "4px" }}>
            or click&nbsp;
            <Link to="/signup">here</Link>
            &nbsp;to register
          </div>
        )}
        {!useLogIn && (
          <div style={{ marginTop: "4px" }}>
            or click&nbsp;
            <Link to="/login">here</Link>
            &nbsp;to sign in
          </div>
        )}
      </form>
      <Modal className={classes.modal} isOpen={modalOpen} centered={true}>
        <ModalHeader className={classes.modal_header}>
          Create Your Account
        </ModalHeader>
        <ModalBody>
          <CreateAccountPart1 />
        </ModalBody>
      </Modal>
    </section>
  );
};

export default AuthForm;
