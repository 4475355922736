import { useEffect, useState } from "react";
import classes from "./_Messages.module.css";
import MessagesList from "./MessagesList";
import MessagesTop from "./MessagesTop";
import MessagesDialog from "./MessagesDialog";
import getRelationshipById from "../../helpers/getRelationshipById";
import getToplineProfiles from "../../helpers/getToplineProfiles";
import { RelationshipProps } from "../../interfaces/relationshipInterface";
import { SlimProfile } from "../../interfaces/slimProfileInterface";
import MessageInput from "./MessageInput";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import filterNotAcceptedRelationships from "../../helpers/filterNotAcceptedRelationships";

import MessageArrived from "./youGotmail.mp3";

const Messages = () => {
  const [idIndex, setIdIndex] = useState<string[]>([]);
  const [relationships, setRelationships] = useState<RelationshipProps[]>([]);
  const [selectedRelationship, setSelectedRelationship] =
    useState<RelationshipProps>();
  const [profiles, setProfiles] = useState<SlimProfile[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<SlimProfile>();
  const [reloadMessages, setReloadMessages] = useState<boolean>(false);
  const [dialogElement, setDialogElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const myId = localStorage.getItem("profileId") || "";
    const fetchRelationships = async () => {
      try {
        if (myId) {
          let [fetchedRelationships, idIndexTemp] = await getRelationshipById(
            myId
          );

          [fetchedRelationships, idIndexTemp] =
            await filterNotAcceptedRelationships({
              relationships: fetchedRelationships,
              idIndex: idIndexTemp,
            });

          setIdIndex(idIndexTemp);
          setRelationships(fetchedRelationships);
          setSelectedRelationship(fetchedRelationships[0]);
        }
      } catch (error) {
        console.error("Error fetching relationships:", error);
      }
    };

    fetchRelationships();
  }, []);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const fetchedProfiles = await getToplineProfiles(relationships);
        setProfiles(fetchedProfiles);

        if (fetchedProfiles.length > 0) {
          console.log("Setup profiles: ", profiles);
          setReloadMessages(true);
          setSelectedProfile(fetchedProfiles[0]);
          setSelectedRelationship(relationships[0]);
        }
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    };

    fetchProfiles();
  }, [relationships]);

  useEffect(() => {
    if (!selectedRelationship || selectedRelationship?.length === 0) {
      return;
    }

    const q = doc(db, "relationships", selectedRelationship?.id || "1");
    const unsubscribe = onSnapshot(q, (snapshot) => {
      messageItemClicked(selectedProfile || {});
      if (dialogElement) {
        scrollToBottom(dialogElement);
      }

      // if (!reloadMessages) {
      //   var AudioPlay = new Audio(MessageArrived);
      //   AudioPlay.play();
      // }

      setReloadMessages(false);
      setSelectedRelationship({
        ...(snapshot.data() as RelationshipProps),
        id: snapshot.id,
      });
    });
    return () => unsubscribe();
  }, [reloadMessages]);

  const messageItemClicked = (profile: SlimProfile) => {
    const index = idIndex.indexOf(profile.id || "");
    setReloadMessages(true);
    setSelectedProfile(profiles[index]);
    setSelectedRelationship(relationships[index]);
  };

  const scrollToBottom = (elem: HTMLElement | null) => {
    setDialogElement(elem);
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  };

  return (
    <div className={classes.messages_page}>
      <div className={classes.messages_page__left}>
        <MessagesList
          profiles={profiles}
          messageItemClicked={messageItemClicked}
        />
      </div>
      <div className={classes.messages_page__right}>
        <MessagesTop {...selectedProfile} />
        <MessagesDialog
          {...selectedRelationship}
          scrollToBottom={scrollToBottom}
        />
        <MessageInput {...selectedRelationship} />
      </div>
    </div>
  );
};

export default Messages;
