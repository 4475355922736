import classes from "./MessagesListItem.module.css";
import AvatarCircle from "./AvatarCircle";
import { SlimProfile } from "../../interfaces/slimProfileInterface";

interface MessagesListItemProps {
  profile: SlimProfile;
  messageItemClicked: (profile: SlimProfile) => void;
}

const MessagesListItem: React.FC<MessagesListItemProps> = ({
  profile,
  messageItemClicked,
}) => {
  const mic = () => {
    messageItemClicked(profile);
  };
  // console.log("ITEM: ", profile, profile.photoUrl?.[0]);
  return (
    <div
      className={classes.messages_list_item}
      onClick={mic}
      data-id={profile?.id}
    >
      <AvatarCircle avatarCircle={profile?.photoUrl?.[0] || ""} />
      <div className={classes.messages_list_item__text_wrapper}>
        <div className={classes.messages_list_item__last_msg_date}>Monday</div>
        <div className={classes.messages_list_item__info}>
          <div style={{ fontWeight: "bold" }}>{profile.screenName}</div>
          <div data-id={profile?.id}>{profile.firstLine}</div>
        </div>
      </div>
    </div>
  );
};

export default MessagesListItem;
