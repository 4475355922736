import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
  DocumentData,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { ProfileProps } from "../interfaces/profileInterface";

interface User extends DocumentData {
  id: string;
}

interface GetProfilesResult {
  recentUsers: ProfileProps[];
  prevSnapshot: DocumentData | null;
}

const getProfiles = async (
  count: number,
  prevSnapshot: DocumentData | undefined | null
): Promise<GetProfilesResult> => {
  const usersCollection = collection(db, "users");
  const myId = localStorage.getItem("profileId") || "";

  let q;

  if (prevSnapshot) {
    const lastVisible = prevSnapshot.docs[prevSnapshot.docs.length - 1];
    q = query(
      usersCollection,
      // where("name", ">=", "receive"),
      // where("name", "<=", "receive" + "\uf8ff"),
      where("sendOrReceive", "==", "receive"),
      orderBy("lastActive", "desc"),
      startAfter(lastVisible),
      limit(count)
    );
  } else {
    q = query(usersCollection, orderBy("lastActive", "desc"), limit(count));
  }

  // Execute the query and get the snapshot of the documents
  const qSnapshot = await getDocs(q);

  // Map the snapshot documents to an array of User objects, and exclude the current user's document
  const recentUsers: ProfileProps[] = qSnapshot.docs
    .map((doc) => {
      const data = doc.data();
      return data && doc.id !== myId
        ? ({ id: doc.id, ...data } as ProfileProps)
        : undefined;
    })
    .filter((user): user is ProfileProps => user !== undefined); // filter out undefined items

  return { recentUsers, prevSnapshot: qSnapshot };
};

export default getProfiles;
