import React, { useState } from "react";
import classes from "./Gallery.module.css";
import AddPhoto from "./AddPhoto";

interface GalleryProps {
  data: any;
  editButtonsPresent: boolean;
  onClick: () => void;
}

const Gallery: React.FC<GalleryProps> = (props) => {
  // const images = Object.values(props);
  const images = props?.data || [];

  return (
    <div className={classes.gallery}>
      <div className={classes.gallery__title}>Gallery:</div>
      <div className={classes.gallery__photo_grid}>
        {props.editButtonsPresent ? <AddPhoto onClick={props.onClick} /> : null}
        {images.length > 0
          ? images.map((img_url: string, i: number) => (
              <img
                className={classes.gallery__image}
                src={img_url}
                alt="some alt"
                key={i}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Gallery;
