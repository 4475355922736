import React, { useState } from "react";
import classes from "./Interests.module.css";
import EditButton from "../widgets/EditButton";

interface InterestsProps {
  interests: string[];
  editButtonsPresent: boolean;
  handleEditClick: () => void;
}

const Interests: React.FC<InterestsProps> = (props) => {
  const [toggleEdit, getToggleEdit] = useState<boolean>(false);
  const interests = Object.values(props?.interests);

  const editHandler = () => {
    getToggleEdit(!toggleEdit);
    props.handleEditClick();
    console.log("Edit Handler");
  };

  return (
    <div className={classes.interests}>
      <div className={classes.interests__title_bar}>
        <div className={classes.interests__title}>Interests:</div>
        {props.editButtonsPresent ? (
          <EditButton editMode={toggleEdit} onClick={editHandler} />
        ) : null}
      </div>
      <div className={classes.interests__grid}>
        {interests?.map((interest, i) => (
          <div className={classes.interests__item} key={i}>
            {interest}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interests;
