import { composeWithDevTools } from 'redux-devtools-extension';
import { configureStore } from '@reduxjs/toolkit';
import { testReducer } from './initialReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const reducer = {
    test: testReducer.reducer,
}
export const store = configureStore({
    reducer,
    devTools: true,
});

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;