import React, { useState } from "react";
import classes from "./AboutMe.module.css";
import EditButton from "../widgets/EditButton";
import { AboutMeProps } from "../../interfaces/aboutMeInterface";

type AboutMe = {
  aboutMe: AboutMeProps;
  editButtonsPresent: boolean;
  handleEditClick: () => void;
};

const AboutMe: React.FC<AboutMe> = ({
  aboutMe,
  editButtonsPresent,
  handleEditClick,
}) => {
  const [toggleEdit, getToggleEdit] = useState<boolean>(false);

  return (
    <div className={classes.about_me}>
      <div className={classes.about_me__title_bar}>
        <div className={classes.about_me__title}>About Me:</div>
        {editButtonsPresent ? (
          <EditButton editMode={toggleEdit} onClick={handleEditClick} />
        ) : null}
      </div>
      <div className={classes.about_me__details}>
        <dl className={classes.about_me__dl}>
          <dt className={classes.about_me__dt}>Body Type:</dt>
          <dd className={classes.about_me__dd}>{aboutMe.bodyType || "---"}</dd>
        </dl>
        <dl className={classes.about_me__dl}>
          <dt className={classes.about_me__dt}>Height:</dt>
          <dd className={classes.about_me__dd}>{aboutMe.height || "---"}</dd>
        </dl>
        <dl className={classes.about_me__dl}>
          <dt className={classes.about_me__dt}>Has Children:</dt>
          <dd className={classes.about_me__dd}>
            {aboutMe.hasChildren || "---"}
          </dd>
        </dl>
        <dl className={classes.about_me__dl}>
          <dt className={classes.about_me__dt}>Smoking:</dt>
          <dd className={classes.about_me__dd}>{aboutMe.smoking || "---"}</dd>
        </dl>
        <dl className={classes.about_me__dl}>
          <dt className={classes.about_me__dt}>Drinking:</dt>
          <dd className={classes.about_me__dd}>{aboutMe.drinking || "---"}</dd>
        </dl>
        <dl className={classes.about_me__dl}>
          <dt className={classes.about_me__dt}>Education:</dt>
          <dd className={classes.about_me__dd}>{aboutMe.education || "---"}</dd>
        </dl>
        <dl className={classes.about_me__dl}>
          <dt className={classes.about_me__dt}>Occupation:</dt>
          <dd className={classes.about_me__dd}>
            {aboutMe.occupation || "---"}
          </dd>
        </dl>
        <dl className={classes.about_me__dl}>
          <dt className={classes.about_me__dt}>Income:</dt>
          <dd className={classes.about_me__dd}>
            {`${aboutMe.income}.00` || "---"}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default AboutMe;
