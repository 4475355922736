import classes from "./Card.module.css";
import { useNavigate } from "react-router-dom";
import { ProfileProps } from "../../interfaces/profileInterface";
import OfferButton from "./OfferButton";
import FavoriteButton from "./FavoriteButton";
import CancelButton from "./cancelButton";
import CardOfferBox from "./cardOfferBox";

interface CardProps {
  profile: ProfileProps;
  type: string;
  amount?: number;
  accepted?: boolean;
  relationshipId?: string;
}

const Card: React.FC<CardProps> = ({ profile, type, amount }) => {
  let navigate = useNavigate();

  const edit = false;
  let addl = null;

  const hideMe = () => {
    const me = document.querySelectorAll(`[data-id=${profile?.id || ""}]`);
    me.forEach((element) => {
      const htmlElement = element as HTMLElement;
      htmlElement.style.display = "none";
    });
  };

  if (type === "offer") {
    addl = (
      <div className={classes.card__buttons_wrapper}>
        <FavoriteButton
          id={profile.id || ""}
          favoritedMe={profile?.favoritedMe}
        />
        <OfferButton profile={profile} />
      </div>
    );
  }

  if (type === "favorites") {
    addl = (
      <div className={classes.card__buttons_wrapper}>
        <FavoriteButton
          id={profile.id || ""}
          favoritedMe={profile?.favoritedMe}
          removeParent={hideMe}
        />
        <OfferButton profile={profile} amount={amount} />
      </div>
    );
  }

  if (type === "viewed_me" || type === "likes_me") {
    addl = (
      <div className={classes.card__buttons_wrapper}>
        <FavoriteButton
          id={profile.id || ""}
          favoritedMe={profile?.favoritedMe}
        />
        <OfferButton profile={profile} amount={amount} />
      </div>
    );
  }

  if (type === "offerMade") {
    addl = (
      <div className={classes.card__buttons_wrapper}>
        <CancelButton id={profile.id} />
        <CardOfferBox id={profile.id} />
      </div>
    );
  }

  const routeChange = () => {
    console.log("Change route: ", profile);
    let path = `/Detail/${profile.id}`;
    navigate(path, {
      state: {
        profile,
        edit,
      },
    });
  };

  return (
    <div className="favorite_card__wrapper" data-id={profile.id}>
      <div
        onClick={() => {
          routeChange();
        }}
        className={classes.card}
        data-id={profile.id}
        style={{
          backgroundImage: `url(${profile.photoUrl ?? ""})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className={classes.grad_black}>
          <h3 className={classes.username}>{profile.screenName}</h3>
          <h4 className={classes.age}>
            {profile.age}, {profile.location}
          </h4>
          <h6 style={{ fontSize: "14px", color: "white", marginLeft: "-20px" }}>
            {profile.id?.trim()}
          </h6>
        </div>
      </div>
      {addl}
    </div>
  );
};

export default Card;
