import React, { useState, useEffect } from "react";
import classes from "../components/Auth/AuthForm.module.css";

const LandingPage = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        setModalOpen(false);
      }}
      type="button"
    >
      &times;
    </button>
  );

  return (
    <>
      <h1 style={{ marginTop: "130px" }}>-S-T-U-D-F-I-N-D-E-R-</h1>
      <img
        src="./images/stud-finder.png"
        alt="stud finder"
        style={{ marginTop: "100px" }}
      />
      <br />
    </>
  );
};

export default LandingPage;
