import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { RelationshipProps } from "../interfaces/relationshipInterface";

const getRelationshipByPair = async (
  id1: string,
  id2: string
): Promise<[RelationshipProps[], string[]]> => {
  try {
    if (!id1 || !id2) {
      return [[], []];
      // throw new Error("Invalid ID");
    }

    const collectionRef = collection(db, "relationships");

    const query1 = query(
      collectionRef,
      where("offererId", "==", id2),
      where("offereeId", "==", id1)
    );
    const query2 = query(
      collectionRef,
      where("offererId", "==", id1),
      where("offereeId", "==", id2)
    );

    const [querySnapshot1, querySnapshot2] = await Promise.all([
      getDocs(query1),
      getDocs(query2),
    ]);

    const idIndex: string[] = [];

    const meFirst = querySnapshot1.docs.map((doc) => {
      const data: RelationshipProps = {
        id: doc.id,
        ...doc.data(),
      } as RelationshipProps;
      idIndex.push(data?.offereeId || "");
      return data;
    });

    const meSecond = querySnapshot2.docs.map((doc) => {
      const data: RelationshipProps = {
        id: doc.id,
        ...doc.data(),
      } as RelationshipProps;
      let temp = data.offereeId;
      idIndex.push(data?.offererId || "");
      return data;
    });

    let returnArray: RelationshipProps[] = [];

    console.log("Me First: ", meFirst);
    console.log("Me Second: ", meSecond);

    if (meFirst.length !== 0) {
      returnArray.push(...meFirst);
    }

    if (meSecond.length !== 0) {
      returnArray.push(...meSecond);
    }

    return [returnArray, idIndex];
  } catch (error) {
    console.error("Error retrieving document:", error);
    throw error;
  }
};

export default getRelationshipByPair;
