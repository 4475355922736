import React, { useRef } from "react";
import classes from "./AboutMe.module.css";
import ButtonGroup from "../widgets/GroupButtons";
import Dropdown, { Option } from "react-dropdown";
import { AboutMeProps } from "../../interfaces/aboutMeInterface";

interface GroupButtonObject {
  id: string;
  label: string;
  checked: boolean;
}

interface AboutMeEditProps {
  aboutMe: AboutMeProps;
  saveHandler: (aboutMe: AboutMeProps) => void;
  cancelHandler: () => void;
}

const AboutMeEdit: React.FC<AboutMeEditProps> = ({
  aboutMe,
  saveHandler,
  cancelHandler,
}) => {
  const occupationInputRef = useRef<HTMLInputElement>(null);

  const saveHandlerLocal = (aboutMe: any) => {
    aboutMe.occupation = occupationInputRef.current?.value || "";
    saveHandler(aboutMe);
  };

  const wantToMeetOptions: GroupButtonObject[] = [
    {
      id: "men",
      label: "Men",
      checked: aboutMe.lookingFor === "men",
    },
    {
      id: "women",
      label: "Women",
      checked: aboutMe.lookingFor === "women",
    },
    {
      id: "both",
      label: "Both",
      checked: aboutMe.lookingFor === "both",
    },
  ];

  const bodyTypeOptions: GroupButtonObject[] = [
    {
      id: "Average",
      label: "Average",
      checked: aboutMe.bodyType === "Average",
    },
    {
      id: "A Few Extra",
      label: "A Few Extra",
      checked: aboutMe.bodyType === "A Few Extra",
    },
    {
      id: "Curvy",
      label: "Curvy",
      checked: aboutMe.bodyType === "Curvy",
    },
    {
      id: "Full Figured",
      label: "Full Figured",
      checked: aboutMe.bodyType === "Full Figured",
    },
    {
      id: "Athletic",
      label: "Athletic",
      checked: aboutMe.bodyType === "Athletic",
    },
    {
      id: "Muscular",
      label: "Muscular",
      checked: aboutMe.bodyType === "Muscular",
    },
    {
      id: "Slender",
      label: "Slender",
      checked: aboutMe.bodyType === "Slender",
    },
    {
      id: "Other",
      label: "Other",
      checked: aboutMe.bodyType === "Other",
    },
  ];

  const childrenOptions: GroupButtonObject[] = [
    {
      id: "children_yes",
      label: "Yes",
      checked: aboutMe.hasChildren == "Yes",
    },
    {
      id: "children_no",
      label: "No",
      checked: aboutMe.hasChildren == "No",
    },
  ];

  const drinkingOptions: GroupButtonObject[] = [
    {
      id: "drinking_yes",
      label: "Yes",
      checked: aboutMe.drinking === "Yes",
    },
    {
      id: "drinking_no",
      label: "No",
      checked: aboutMe.drinking === "No",
    },
    {
      id: "drinking_sometimes",
      label: "Sometimes",
      checked: aboutMe.drinking === "Sometimes",
    },
  ];

  const smokingOptions: GroupButtonObject[] = [
    {
      id: "smoke_yes",
      label: "Yes",
      checked: aboutMe.smoking === "Yes",
    },
    {
      id: "smoke_no",
      label: "No",
      checked: aboutMe.smoking === "No",
    },
    {
      id: "smoke_sometime",
      label: "Sometimes",
      checked: aboutMe.smoking === "Sometimes",
    },
  ];

  const heights = ["Tall", "Short", "Average"];

  const education = [
    "Some High School",
    "High School",
    "Some College",
    "College",
    "Graduate School",
    "PhD / Post Doctoral",
  ];

  const income = [
    "Less than $50,000",
    "$50,000 - $100,000",
    "$100,000 - $250,000",
    "$250,000 - $500,000",
    "$500,000 - $1,000,000",
    "$1,000,000+",
  ];

  const radioHandler = (group: string, id: string) => {
    id = id.includes("no") ? "No" : id;
    id = id.includes("yes") ? "Yes" : id;
    id = id.includes("sometimes") ? "Sometimes" : id;
    aboutMe[group] = id;
  };

  const heightHandler = (option: Option) => {
    const { value, label } = option;
    aboutMe["height"] = label?.toString() || "";
  };

  const incomeHandler = (option: Option) => {
    const { value, label } = option;
    aboutMe["income"] = label?.toString() || "";
    console.log("AboutMeEdit:saveHandlerLocal:aboutMe:", aboutMe);
  };

  const educationHandler = (option: Option) => {
    const { value, label } = option;
    aboutMe["education"] = label?.toString() || "";
  };

  return (
    <div className={`${classes.about_me_edit} ${classes.about_me}`}>
      <div className={classes.about_me__title_bar}>
        <div className={classes.about_me__title}>About Me:</div>
        <div className={classes.about_me__cancel} onClick={cancelHandler}>
          Cancel
        </div>
      </div>
      <div className={classes.about_me__details_edit}>
        <ButtonGroup
          label="I want to meet...."
          data={wantToMeetOptions}
          group="lookingFor"
          handler={radioHandler}
          type="radio"
        />
        <ButtonGroup
          label="Body Type (Choose One):"
          data={bodyTypeOptions}
          group="bodyType"
          handler={radioHandler}
          type="radio"
        />
        <br />
        <Dropdown
          options={heights}
          onChange={heightHandler}
          placeholder="Height"
          value={aboutMe.height}
        />
        <br />
        <ButtonGroup
          label="Children:"
          data={childrenOptions}
          group="hasChildren"
          handler={radioHandler}
          type="radio"
        />
        <ButtonGroup
          label="Smoking:"
          data={smokingOptions}
          group="smoking"
          handler={radioHandler}
          type="radio"
        />
        <ButtonGroup
          label="Drinking:"
          data={drinkingOptions}
          group="drinking"
          handler={radioHandler}
          type="radio"
        />
        <br />
        <Dropdown
          options={education}
          onChange={educationHandler}
          placeholder="Education"
          value={aboutMe.education}
        />
        <br />
        <input
          className={classes.about_me__occupation_edit}
          type="text"
          placeholder="Occupation"
          ref={occupationInputRef}
          defaultValue={aboutMe.occupation}
        />
        <br />
        <Dropdown
          options={income}
          onChange={incomeHandler}
          placeholder="Income"
          value={aboutMe.income}
        />
        <br />
      </div>
      <div
        className={classes.about_me__save}
        onClick={() => saveHandlerLocal(aboutMe)}
      >
        Save
      </div>
    </div>
  );
};

export default AboutMeEdit;
