import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import { RelationshipProps } from "../interfaces/relationshipInterface";
import getCurrentTimestamp from "./getCurrentTimestamp";

const saveRelationship = async (relationship: RelationshipProps) => {
  if (!relationship) {
    console.error("Relationship is undefined");
    return;
  }

  console.log(
    "Save Relationship: ",
    relationship,
    "Offerer: ",
    relationship.offererId,
    "Offeree: ",
    relationship.offereeId
  );

  relationship.lastUpdated = getCurrentTimestamp();

  const collectionRef = collection(db, "relationships");

  const q = query(
    collectionRef,
    where("offererId", "==", relationship.offererId),
    where("offereeId", "==", relationship.offereeId)
  );

  const querySnapshot = await getDocs(q);
  console.log("Returned from save await");

  if (!querySnapshot.empty) {
    // Found an existing relationship

    const relationshipDoc = querySnapshot.docs[0];

    console.log(
      "Found an existing relationship for ",
      relationship.offererId,
      " and ",
      relationship.offereeId,
      relationshipDoc.id
    );

    await updateDoc(relationshipDoc.ref, {
      ...relationship,
      id: relationshipDoc.id,
    });
    console.log("Updated existing relationship.");
  } else {
    const newRel = await addDoc(collectionRef, { ...relationship, id: "" });
    console.log("Creating new relationship - id: ", newRel.id);

    // Update the document with the generated ID
    await updateDoc(doc(collectionRef, newRel.id), { id: newRel.id });
  }
};

export default saveRelationship;
