import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { RelationshipProps } from "../interfaces/relationshipInterface";

const getRelationshipById = async (
  id: string
): Promise<[RelationshipProps[], string[]]> => {
  try {
    if (!id) {
      return [[], []];
      // throw new Error("Invalid ID");
    }

    const collectionRef = collection(db, "relationships");

    const query1 = query(collectionRef, where("offererId", "==", id));
    const query2 = query(collectionRef, where("offereeId", "==", id));

    const [querySnapshot1, querySnapshot2] = await Promise.all([
      getDocs(query1),
      getDocs(query2),
    ]);

    const idIndex: string[] = [];

    const meFirst = querySnapshot1.docs.map((doc) => {
      const data: RelationshipProps = {
        id: doc.id,
        ...doc.data(),
      } as RelationshipProps;
      idIndex.push(data?.offereeId || "");
      return data;
    });

    const meSecond = querySnapshot2.docs.map((doc) => {
      const data: RelationshipProps = {
        id: doc.id,
        ...doc.data(),
      } as RelationshipProps;
      let temp = data.offereeId;
      idIndex.push(data?.offererId || "");
      data.offereeId = data.offererId;
      data.offererId = temp;

      return data;
    });

    const returnArray: RelationshipProps[] = [...meFirst, ...meSecond];

    console.log("Found relationships: ", returnArray);
    console.log("Id Index: ", idIndex);

    return [returnArray, idIndex];
  } catch (error) {
    console.error("Error retrieving document:", error);
    throw error;
  }
};

export default getRelationshipById;
