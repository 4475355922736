import React, { useState, useEffect } from "react";
import classes from "./_Offers.module.css";

import OffersTabs from "./OffersTabs";
import OffersRcvdGrid from "./OffersRcvdGrid";
import OffersSentGrid from "./OffersSentGrid";

import getRelationshipsByOfferer from "../../helpers/getRelationshipsByOfferer";
import getRelationshipsByOfferee from "../../helpers/getRelationshipsByOfferee";
import getProfilesByIds from "../../helpers/getProfilesByIds";

import { RelationshipProps } from "../../interfaces/relationshipInterface";
import { ProfileProps } from "../../interfaces/profileInterface";

const Offers: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>("requests");
  const [offersSent, setOffersSent] = useState<RelationshipProps[]>([]);
  const [offersSentIndexes, setOffersSentIndexes] = useState<string[]>([]);
  const [offersReceived, setOffersReceived] = useState<RelationshipProps[]>([]);
  const [offersReceivedIndexes, setOffersReceivedIndexes] = useState<string[]>(
    []
  );

  const [offersSentProfiles, setOffersSentProfiles] = useState<ProfileProps[]>(
    []
  );
  const [offersReceivedProfiles, setOffersReceivedProfiles] = useState<
    ProfileProps[]
  >([]);

  const fetchRelationships = async () => {
    const myId = localStorage.getItem("profileId") || "";
    try {
      const [received, receivedIdIndex] = await getRelationshipsByOfferee(myId);
      setOffersReceived(received);
      setOffersReceivedIndexes(receivedIdIndex);

      const [sent, sentIdIndex] = await getRelationshipsByOfferer(myId);
      setOffersSent(sent);
      setOffersSentIndexes(sentIdIndex);

      console.log("Offers received:", offersReceived);
      console.log("Offers received indexes:", offersReceivedIndexes);
      console.log("Offers sent:", offersSent);
      console.log("Offers sent indexes:", offersSentIndexes);
    } catch (error) {
      console.error("Error fetching relationships:", error);
    }
  };

  useEffect(() => {
    fetchRelationships();
  }, []);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        console.log(
          "Getting profiles for offers received:",
          offersReceivedIndexes
        );
        const fetchedProfiles = await getProfilesByIds(offersReceivedIndexes);
        setOffersReceivedProfiles(fetchedProfiles);
        console.log("Offers received profiles:", fetchedProfiles);
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    };

    fetchProfiles();
  }, [offersReceivedIndexes]);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        console.log("Getting profiles for offers sent:", offersSentIndexes);
        const fetchedProfiles = await getProfilesByIds(offersSentIndexes);
        setOffersSentProfiles(fetchedProfiles);
        console.log("Offers sent profiles:", fetchedProfiles);
      } catch (error) {
        console.error("Error fetching profiles:", error);
      }
    };

    fetchProfiles();
  }, [offersSentIndexes]);

  return (
    <div className={classes.offers}>
      <OffersTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === "requests" ? (
        <OffersRcvdGrid
          profiles={offersReceivedProfiles}
          fetchRelationships={fetchRelationships}
        />
      ) : (
        <OffersSentGrid profiles={offersSentProfiles} />
      )}
    </div>
  );
};

export default Offers;
