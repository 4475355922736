import {
  collection,
  query,
  where,
  getDoc,
  updateDoc,
  addDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import { ProfileProps } from "../interfaces/profileInterface";

const saveProfileById = async (profile: ProfileProps) => {
  try {
    // Check if a document with the same id exists
    const userDocRef = doc(db, "users", profile.id || "");

    const querySnapshot = await getDoc(userDocRef);

    const stringFields = [
      "lastSeenFrom",
      "favorite",
      "lastActive",
      "screenName",
      "age",
      "dob",
      "location",
      "gender",
      "sendOrReceive",
      "lookingFor",
      "email",
      "bodyType",
      "height",
      "weight",
      "hasChildren",
      "smoking",
      "drinking",
      "education",
      "occupation",
      "income",
      "description",
      "ifd",
    ];

    const arrayFields = [
      "photoUrl",
      "interests",
      "favorites",
      "favoritesTimestamp",
      "favoritedMe",
      "favoritedMeTimestamp",
      "visitedMe",
      "visitedMeTimestamp",
    ];

    const numberFields = ["currentOffer", "acceptedOffer"];

    let newProfileData: ProfileProps = {};

    if (querySnapshot.exists()) {
      console.log("saveProfileById - Updating existing document");
      stringFields.forEach((field) => {
        if (profile?.[field]) {
          newProfileData[field] = profile[field];
        }
      });

      arrayFields.forEach((field) => {
        if (profile?.[field]) {
          newProfileData[field] = profile[field];
        }
      });

      numberFields.forEach((field) => {
        if (profile?.[field]) {
          newProfileData[field] = profile[field];
        }
      });

      await updateDoc(userDocRef, newProfileData);
    } else {
      console.log("Adding new document");
      stringFields.forEach((field) => {
        newProfileData[field] = profile?.[field] || "";
      });

      arrayFields.forEach((field) => {
        newProfileData[field] = profile?.[field] || [];
      });

      numberFields.forEach((field) => {
        newProfileData[field] = profile?.[field] || 0;
      });

      const newProfile = await addDoc(collection(db, "users"), newProfileData);
      console.log("New profile ID: ", newProfile.id);
      localStorage.setItem("profileId", newProfile.id);
    }
    console.log("Item saved.");
  } catch (error) {
    alert("Error writing data: " + error);
  }
};

export default saveProfileById;
