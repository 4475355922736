import { PencilSimple } from "@phosphor-icons/react";
import classes from "./EditButton.module.css";

interface EditButtonProps {
  editMode: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

const editButton: React.FC<EditButtonProps> = ({ editMode, onClick }) => {
  return (
    <span
      className={`${classes.edit_button} ${
        editMode ? classes.edit_button__edit : ""
      }`}
      onClick={onClick}
    >
      <span style={{ paddingRight: "4px" }}>Edit</span>
      <PencilSimple size={20} weight="light" />
    </span>
  );
};

export default editButton;
