import React, { useRef, useState } from "react";
import ButtonGroup from "../widgets/GroupButtons";
import Dropdown from "react-dropdown";
import classes from "./CreateAccountPart1.module.css";
import saveProfileByEmail from "../../helpers/saveProfileByEmail";
import getAgeFromDate from "../../helpers/getAgeFromDate";
import { Crosshair } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { ProfileProps } from "../../interfaces/profileInterface";

interface GroupButtonObject {
  id: string;
  label: string;
  checked: boolean;
}

const genderOptions: GroupButtonObject[] = [
  {
    id: "woman",
    label: "Woman",
    checked: true,
  },
  {
    id: "man",
    label: "Man",
    checked: false,
  },
];

const wantToOptions: GroupButtonObject[] = [
  {
    id: "purchase",
    label: "Make Offers",
    checked: true,
  },
  {
    id: "receive",
    label: "Receive Offers",
    checked: false,
  },
];

const wantToMeetOptions: GroupButtonObject[] = [
  {
    id: "men",
    label: "Men",
    checked: true,
  },
  {
    id: "women",
    label: "Women",
    checked: false,
  },
  {
    id: "both",
    label: "Both",
    checked: false,
  },
];

const CreateAccountPart1 = () => {
  const navigate = useNavigate();

  let radioButtonValues: { [key: string]: string } = {
    gender: "woman",
    buy_sell: "buy",
    want_gender: "men",
  };

  const userEmail = localStorage.getItem("userEmail");
  const screenNameInputRef = useRef<HTMLInputElement>(null);
  const locationInputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days: string[] = Array.from(Array(31).keys()).map((i) =>
    (i + 1).toString()
  );
  const years: string[] = Array.from(Array(104).keys()).map((i) =>
    (i + 1920).toString()
  );

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log("SUBMIT");
    setIsLoading(true);

    if (!userEmail) {
      alert("You must be logged in to create an account.");
      return;
    }

    const enteredScreenName = screenNameInputRef.current?.value || "";
    const enteredLocation = locationInputRef.current?.value || "";
    const enteredDob = `${month}-${day}-${year}`;

    const dob = new Date(+year, months.indexOf(month) - 1, +day);
    const age = getAgeFromDate(dob);

    localStorage.setItem("screenName", enteredScreenName);

    // Add validation

    let profile: ProfileProps & { [x: string]: any } = {
      email: userEmail,
      screenName: enteredScreenName,
      dob: enteredDob,
      age: age,
      location: enteredLocation,
      gender: radioButtonValues["gender"],
      sendOrReceive: radioButtonValues["buy_sell"],
      lookingFor: radioButtonValues["want_gender"],
    };

    console.log("isLoading: ", isLoading);

    profile = (await saveProfileByEmail(profile)) as ProfileProps & {
      [x: string]: any;
    };

    setIsLoading(false);
    navigate("/profile");
  };

  const radioButtonHandler = (group: string | undefined, value: string) => {
    if (group) {
      radioButtonValues[group] = value;
    }
    console.log(group, value, radioButtonValues);
  };

  return (
    <form className={classes.acct_part_1} onSubmit={submitHandler}>
      <ButtonGroup
        label="I am a...."
        data={genderOptions}
        group="gender"
        handler={radioButtonHandler}
        type="radio"
      />
      <ButtonGroup
        label="I want to...."
        data={wantToOptions}
        group="buy_sell"
        handler={radioButtonHandler}
        type="radio"
      />
      <ButtonGroup
        label="I want to meet...."
        data={wantToMeetOptions}
        group="want_gender"
        handler={radioButtonHandler}
        type="radio"
      />
      <div className={classes.acct_part_1__input_box}>
        <div className={classes.acct_part_1__input_label}>Display Name:</div>
        <input
          type="text"
          placeholder="Display Name"
          ref={screenNameInputRef}
          required
          style={{ marginLeft: "6px", width: "300px" }}
        />
      </div>
      <div className={classes.acct_part_1__input_box}>
        <div className={classes.acct_part_1__input_label}>Location:</div>
        <input
          type="text"
          placeholder="Location"
          ref={locationInputRef}
          required
          style={{ marginLeft: "6px", marginRight: "6px", width: "300px" }}
        />
        <span className={classes.acct_part_1__locator}>
          <Crosshair size={30} onClick={() => alert("Coming Soon!")} />
        </span>
      </div>
      <div
        className={classes.acct_part_1__input_label}
        style={{ marginLeft: "10px", marginTop: "10px" }}
      >
        Birthdate:
      </div>
      <div className={classes.acct_part_1__dob}>
        <div style={{ width: "200px" }}>
          <Dropdown
            options={months}
            onChange={(e) => {
              console.log("Changed Month: ", e.value);
              setMonth(e.value);
            }}
            placeholder="Month"
          />
        </div>
        <div style={{ width: "100px", marginLeft: "10px" }}>
          <Dropdown
            options={days}
            placeholder="Day"
            onChange={(e) => setDay(e.value)}
          />
        </div>
        <div style={{ width: "100px", marginLeft: "10px" }}>
          <Dropdown
            options={years}
            placeholder="Year"
            onChange={(e) => setYear(e.value)}
          />
        </div>
      </div>
      <button className={classes.acct_part_1__continue_button}>
        {isLoading ? <Spinner size="sm"></Spinner> : "Continue"}
      </button>
    </form>
  );
};

export default CreateAccountPart1;
