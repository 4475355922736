import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./OfferAcceptedBox.module.css";
import { ProfileProps } from "../../interfaces/profileInterface";
import { RelationshipProps } from "../../interfaces/relationshipInterface";
import OfferSentConfirmModal from "../widgets/OfferSentConfirmModal";

interface OfferBoxProps {
  profile: ProfileProps;
  relationship?: RelationshipProps;
  closeModal?: (amount: number) => void;
}

const IAcceptedBox: React.FC<OfferBoxProps> = ({
  profile,
  relationship,
  closeModal,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(
    relationship?.offers ? Number(relationship.offers[0].amount) : 0
  );
  const navigate = useNavigate();

  useEffect(() => {
    setAmount(
      relationship?.offers
        ? Number(relationship.offers[relationship.offers.length - 1].amount)
        : 0
    );
  }, [relationship]);

  const gotoMessages = () => {
    navigate(`/messages`);
  };

  return (
    <div className={classes.offer_accepted_box}>
      <div className={classes.offer_accepted_box__title}>
        You accepted {profile.screenName}'s request.
      </div>
      <div className={classes.offer_accepted_box__subtitle}>
        Start a conversation to plan your date!
      </div>
      <div className={classes.offer_accepted_box__amount}>
        ${amount?.toString()}
      </div>
      <div
        className={classes.offer_accepted_box__button}
        onClick={gotoMessages}
      >
        Go to Messages
      </div>
      {relationship?.id}
    </div>
  );
};

export default IAcceptedBox;
