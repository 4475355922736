import React, { useContext } from "react";
import { GlobalContent } from "../interfaces/globalContent";
import AuthContext from "../store/auth-context";

import Detail from "../components/Detail/_Detail";

const Profile = () => {
  const myId = localStorage.getItem("profileId") || "";

  console.log("myId from localStorage: ", myId);

  const ctx = useContext<GlobalContent>(AuthContext);
  if (ctx.isLoggedIn) {
    return (
      <Detail
        editButtonsPresent={true}
        recordId={myId}
        offerBoxPresent={false}
      />
    );
  } else {
    return <div></div>;
  }
};

export default Profile;
