import React, { useState } from "react";
import GroupButton from "./GroupButton";
import classes from "./GroupButtons.module.css";

interface ButtonObject {
  id: string;
  label: string;
  checked: boolean;
}

interface ButtonGroupProps {
  label: string;
  data: ButtonObject[];
  group: string;
  type: string;
  handler: (id: string, value: string) => void;
}

const GroupButtons = (props: ButtonGroupProps) => {
  // Initialize selections state based on props.data
  const initialState: { [key: string]: boolean } = {};
  props.data.forEach((item: ButtonObject) => {
    initialState[item.id] = item.checked;
  });

  console.log("initialState", initialState);

  const [selections, setSelections] = useState<{ [key: string]: boolean }>(
    initialState
  );

  const onChangeSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, dataset } = e.target;
    const group: string = dataset.group || "";
    const newSelections = { ...selections };

    let value: string = "";

    console.log("SELECTIONS: ", selections, "NAME: ", name);

    if (props.type === "checkbox") {
      newSelections[name] = !selections[name];
      value = name;
    } else {
      Object.keys(newSelections).forEach((selection) => {
        newSelections[selection] = selection === name;
        if (selection === name) {
          value = selection;
        }
      });
    }

    setSelections(newSelections);
    props.handler(group, value);
  };

  return (
    <>
      <div
        className={classes.label}
        style={{ paddingBottom: "0px", textAlign: "left" }}
      >
        {props.label}
      </div>
      <div className={classes.group_buttons__wrapper}>
        {props.data.map((item: ButtonObject, index) => (
          <GroupButton
            key={index}
            group={props.group}
            id={item.id}
            name={item.id}
            onChange={onChangeSelection}
            checked={selections[item.id]}
            label={item.label}
            type={props.type}
          />
        ))}
      </div>
    </>
  );
};

export default GroupButtons;
