import classes from "./RequestDate.module.css";
import OfferBox from "../Detail/OfferBox";
import { Link } from "react-router-dom";
import { ProfileProps } from "../../interfaces/profileInterface";

const RequestDate: React.FC<ProfileProps> = ({ profile, confirmModal }) => {
  const offerStatusChanged = (newStatus: string) => {};

  return (
    <div className={classes.request_date__wrapper}>
      <div className={classes.request_date__request_date_wrapper}>
        <div className={classes.request_date__photo_wrapper}>
          <img
            src={profile?.photoUrl?.[0]}
            alt="user"
            className={classes.request_date__photo}
          />
        </div>
        <div className={classes.request_date__text}>Request A Date</div>
      </div>
      <div className={classes.request_date__subtext}>
        Make a Studfinder Date Offer to {profile?.screenName}
      </div>
      <OfferBox
        profile={profile}
        closeModal={confirmModal}
        offerStatusChanged={offerStatusChanged}
      />
      <div className={classes.request_date__subtext}>
        - OR -<div>Ask him to suggest an amount</div>
        <Link to="/">Cancel</Link>
      </div>
      <div className={classes.request_date__subtext}>
        <Link to="/">Read Safe Dating Tips</Link>&nbsp;|&nbsp;
        <Link to="/">Read Community Guidelines</Link>
      </div>
    </div>
  );
};

export default RequestDate;
