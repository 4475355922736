import { useNavigate } from "react-router-dom";
import classes from "./Prefs.module.css";
import ToggleSwitch from "./ToggleSwitch";
import {
  HandPalm,
  FirstAid,
  Phone,
  LinkSimpleHorizontal,
  ArrowLeft,
} from "@phosphor-icons/react";

const AccountPrefs = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <ArrowLeft
        size={40}
        onClick={props.closeSlider}
        className={classes.prefs__arrow}
      />
      <div className={classes.prefs__wrapper}>
        <div className={classes.prefs__header}>
          <div className={classes.prefs__title}>Account Preferences</div>
        </div>
        <div className={classes.prefs__section}>Login Options</div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label_lower}>Login with Google</div>
          <ToggleSwitch />
        </div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/");
          }}
        >
          <div className={classes.prefs__label}>Logout All Devices</div>
          <LinkSimpleHorizontal size={32} />
        </div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/password");
          }}
        >
          <div className={classes.prefs__label}>Change Email & Password</div>
          <LinkSimpleHorizontal size={32} />
        </div>
        <div className={classes.prefs__section}>Account History</div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/");
          }}
        >
          <div className={classes.prefs__label}>Purchase History</div>
          <LinkSimpleHorizontal size={32} />
        </div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/");
          }}
        >
          <div className={classes.prefs__label}>Cancelled Orders</div>
          <LinkSimpleHorizontal size={32} />
        </div>
        <div
          className={classes.prefs__section}
          onClick={() => {
            props.linkOut();
            navigate("/");
          }}
        >
          Deactive Account
        </div>
        <div className={classes.prefs__item}>
          <div className={classes.prefs__label}>Deactivate My Account</div>
          <HandPalm size={32} />
        </div>
        <div className={classes.prefs__section}>Data Protection</div>
        <div
          className={classes.prefs__item}
          onClick={() => {
            props.linkOut();
            navigate("/contact_us");
          }}
        >
          <div
            className={classes.prefs__label_lower}
            onClick={() => {
              props.linkOut();
              navigate("/");
            }}
          >
            Request Info
          </div>
          <Phone size={32} />
        </div>
        <div className={classes.prefs__item}>
          <div
            className={classes.prefs__label_lower}
            onClick={() => {
              props.linkOut();
              navigate("/");
            }}
          >
            Revoke Consent
          </div>
          <FirstAid size={32} />
        </div>
        <div className={classes.prefs__item}>
          <div
            className={classes.prefs__label_lower}
            onClick={() => {
              props.linkOut();
              navigate("/");
            }}
          >
            Delete Account
          </div>
          <FirstAid size={32} />
        </div>
      </div>
    </>
  );
};

export default AccountPrefs;
