import classes from "./InterestsCard.module.css";
import Card from "../widgets/Card";
import { ProfileProps } from "../../interfaces/profileInterface";

interface Offer {
  accepted: boolean;
  amount: number;
  createdAt: string;
}

interface InterestsCardProps {
  profile: ProfileProps;
  type: string;
  key: string | null;
  offerHistory?: Offer[];
  relationshipId?: string;
}

const InterestsCard: React.FC<InterestsCardProps> = (props) => {
  console.log("OFFER HISTORY: ", props.offerHistory);
  const latestOffer =
    props?.offerHistory?.[props.offerHistory.length - 1]?.amount || 0;

  const offerAccepted =
    props?.offerHistory?.[props.offerHistory.length - 1]?.accepted || false;

  return (
    <div>
      <Card
        profile={props.profile}
        type={props.type}
        amount={latestOffer}
        accepted={offerAccepted}
        relationshipId={props.relationshipId}
      />
    </div>
  );
};

export default InterestsCard;
