import React, { useState } from "react";
import classes from "./InterestsTabs.module.css";

interface TabsProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const InterestsTabs: React.FC<TabsProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  const tabClick = (e: any) => {
    setSelectedTab(e.target.dataset.name);
  };

  return (
    <div className={classes.interests_tabs}>
      <div
        onClick={tabClick}
        className={`${classes.interests_tabs__item} ${
          selectedTab === "viewed_me" && classes.interests_tabs__item_selected
        }`}
        data-name="viewed_me"
      >
        Viewed Me
      </div>
      <div
        onClick={tabClick}
        className={`${classes.interests_tabs__item} ${
          selectedTab === "likes_me" && classes.interests_tabs__item_selected
        }`}
        data-name="likes_me"
      >
        Favorited Me
      </div>
      <div
        onClick={tabClick}
        className={`${classes.interests_tabs__item} ${
          selectedTab === "i_like" && classes.interests_tabs__item_selected
        }`}
        data-name="i_like"
      >
        My Favorites
      </div>
    </div>
  );
};

export default InterestsTabs;
