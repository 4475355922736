import React, { useRef } from "react";
import classes from "./MessageInput.module.css";
import { Smiley, Image, TelegramLogo } from "@phosphor-icons/react";
import { RelationshipProps } from "../../interfaces/relationshipInterface";
import saveMessage from "../../helpers/saveMessage";

const MessageInput = (relationship: RelationshipProps) => {
  // console.log("Message Input: ", relationship.id);

  const addTextToChat = () => {
    let message = "";
    if (inputRef.current) {
      message = inputRef.current.value;
      inputRef.current.value = "";

      if (message) {
        saveMessage({
          relationship: relationship || null,
          message: message,
        });
      }
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      addTextToChat();
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={classes.message_input}>
      <div className={classes.message_input__message_bar}>
        <Smiley size={30} />
        <Image size={30} />
        <input
          className={classes.message_input__input}
          type="text"
          ref={inputRef}
          onKeyUp={onKeyDown}
        />
        <div className={classes.message_input__send} onClick={addTextToChat}>
          <TelegramLogo size={26} />
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
